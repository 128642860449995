import React from 'react';
import { ForgotPasswordContainerProps } from '../../..';
import FFlogo from '../../assets/images/factionfinancialwhite.png';

export default function ForgotPasswordContainer(props: ForgotPasswordContainerProps) {
    return (
        <div className="w-full flex flex-wrap bg-black">
            <div className="w-full md:w-1/2 flex flex-col">
                <div className="flex justify-center px-32 pt-16 pb-8 xl:w-3/4 overflow-hidden">
                    <img alt="Faction Financial Logo" className="w-full h-full" src={FFlogo}></img>
                </div>
                <div className="flex flex-col justify-center md:justify-start md:pt-0 px-8 md:px-24 lg:px-32">
                    <p className="text-center text-3xl text-white">Enter your email.</p>
                    <form className="flex flex-col pt-3 md:pt-8" id="forgotPasswordForm" onSubmit={props.onSubmitEmail}>
                        <div className="flex flex-col pt-4">
                            <label htmlFor="Email" className="text-lg text-white">
                                Email
                            </label>
                            <input
                                required={true}
                                type="email"
                                id="email"
                                placeholder="Email"
                                className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>

                        <input
                            type="submit"
                            value="Send Password Reset Link"
                            className="bg-gray-900 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-12 rounded text-center"
                        />
                    </form>
                    {props.result && (
                        <div
                            className="mt-10 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                            role="alert"
                        >
                            <span className="block sm:inline">{props.result}</span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3" />
                        </div>
                    )}
                    {props.error && (
                        <div
                            className="mt-10 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                            role="alert"
                        >
                            <span className="block sm:inline">{props.error}</span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3" />
                        </div>
                    )}
                    <a
                        href="/login"
                        className="bg-gray-900 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-12 rounded text-center"
                    >
                        Go back to Login
                    </a>
                </div>
            </div>
            <div className="w-1/2 shadow-2xl">
                <img
                    alt="company logo"
                    className="object-cover w-full h-screen hidden md:block"
                    src="https://images.pexels.com/photos/2129796/pexels-photo-2129796.png?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                />
            </div>
        </div>
    );
}
