import React, { useEffect, useState } from 'react';
import { checkPasswordResetLink, resetPassword } from '../../helper/DataServiceProvider';
import StaticNavbar from '../Navbar/StaticNavbar';

export default function PasswordResetPage(props: { path: string }) {
    const [expiredState, setExpiredState] = useState<boolean>(true);
    const [passwordResetErrorMessage, setPasswordResetErrorMessage] = useState<string | undefined>(undefined);
    const [passwordResetSuccessMessage, setPasswordResetSuccessMessage] = useState<string | undefined>(undefined);
    const path = '/password-reset/';
    var index = props.path.indexOf(path);
    const token = props.path.slice(0, index) + props.path.slice(index + path.length);
    const onSubmit = (event: any) => {
        event.preventDefault();
        setPasswordResetErrorMessage(undefined);
        setPasswordResetSuccessMessage(undefined);
        const data: any = {
            password: event.target.password.value,
        };
        resetPassword(token, data.password)
            .then((result: string) => {
                console.log('password change successful');
                setPasswordResetSuccessMessage(result);
            })
            .catch((e: Error) => {
                console.log('password change failed');
                setPasswordResetErrorMessage(e.message);
            });
    };
    useEffect(() => {
        checkPasswordResetLink(token)
            .then(() => {
                setExpiredState(false);
                console.log('link is still valid');
            })
            .catch((error) => {
                setExpiredState(true);
                console.log('expired link');
            });
    }, [token]);
    let content = undefined;
    if (expiredState) {
        content = (
            <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 flex-grow">
                <div className="sm:text-center lg:text-left">
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        Your password reset link is expired or not valid. Please re-request a new link.
                    </p>
                    <p>
                        <a href="/login" className="underline font-semibold">
                            Go back to Login Page
                        </a>
                    </p>
                    <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"></p>
                    <div></div>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 flex-grow">
                <div className="sm:text-center lg:text-left">
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        Please enter your new password
                    </p>
                    {passwordResetSuccessMessage !== undefined ? (
                        <div>
                            <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                You have successfully changed your password. Please relogin.
                            </p>
                            <p>
                                <a href="/login" className="underline font-semibold">
                                    Go back to Login Page
                                </a>
                            </p>
                        </div>
                    ) : (
                        <form className="flex flex-col pt-3 md:pt-8" id="loginForm" onSubmit={onSubmit}>
                            <div className="flex flex-col pt-4">
                                <label htmlFor="password" className="text-lg">
                                    Password
                                </label>
                                <input
                                    required={true}
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="Password"
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            {passwordResetErrorMessage && (
                                <div
                                    className="mt-10 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                    role="alert"
                                >
                                    <span className="block sm:inline">{passwordResetErrorMessage}</span>
                                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3" />
                                </div>
                            )}
                            <input
                                type="submit"
                                value="Change Password"
                                className="bg-black text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8"
                            />
                        </form>
                    )}
                    <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"></p>
                    <div></div>
                </div>
            </div>
        );
    }
    return (
        <div className="flex flex-col min-h-screen">
            <StaticNavbar />
            {content}
        </div>
    );
}
