import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { getPredictionsCountWithFilter } from '../../helper/DataServiceProvider';
import { useAsyncError } from '../../helper/util';
import HistoryChart from '../Prediction/PredictionCard/HistoryChart';

export default function History() {
    const MAX_CARDS_PER_PAGE = 20;
    const [totalPredictionCount, setPredictionCount] = useState(-1);
    const [filter, setFilter] = useState(undefined);
    const throwError = useAsyncError();
    const updatePredictionCount = async function (filter: string | undefined) {
        try {
            const predictionCount: number = await getPredictionsCountWithFilter(filter);
            setPredictionCount(predictionCount);
        } catch (error) {
            throwError(error);
        }
    };
    useEffect(() => {
        let isMounted = true; // note this flag denote mount status
        getPredictionsCountWithFilter(filter).then((count) => {
            if (isMounted) setPredictionCount(count);
        });
        return () => {
            isMounted = false;
        }; // use effect cleanup to set flag false, if unmounted
    }, [filter]);
    return (
        <div className="bg-black">
            <motion.div
                className="flex bg-black shadow p-4 mt-16 text-white"
                animate={{
                    opacity: [0, 1],
                }}
                transition={{
                    duration: 2,
                    ease: 'easeIn',
                    times: [0, 0.3],
                }}
            >
                <input
                    className=" border border-gray-700 w-full py-4 px-6 leading-tight focus:outline-none text-3xl  bg-black"
                    id="filter"
                    type="text"
                    value={filter === undefined ? '' : filter}
                    placeholder="Filter Predictions"
                    maxLength={140}
                    onChange={(e: React.ChangeEvent<any>) => {
                        updatePredictionCount(e.target.value);
                        setFilter(e.target.value);
                    }}
                ></input>
                <svg
                    className="text-gray-600 h-full w-16 fill-current ml-6"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.966 56.966"
                    width="512px"
                    height="512px"
                >
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                </svg>
            </motion.div>
            <div className="p-0 sm:p-6 pt-10 flex-1 bg-black">
                <HistoryChart
                    detailed={true}
                    totalPredictionCount={totalPredictionCount}
                    filter={filter}
                    maxCardsPerPage={MAX_CARDS_PER_PAGE}
                />
            </div>
        </div>
    );
}
