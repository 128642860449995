import React from 'react';
import FFlogo from '../../assets/images/factionfinancialwhite.png';

export default function RegisterSuccessContainer(props: {}) {
    return (
        <div className="w-full flex flex-wrap bg-black min-h-screen">
            <div className="w-full md:w-1/2 flex flex-col">
                <div className="flex justify-center px-32 pt-16 pb-8 xl:w-3/4 overflow-hidden">
                    <img alt="Faction Financial Logo" className="w-full" src={FFlogo}></img>
                </div>
                <div className="flex flex-col justify-center md:justify-start my-auto md:pt-0 px-8 md:px-24 lg:px-32">
                    <p className="text-center text-3xl text-white">
                        Your email has been successfully registered. You need to click the verification email in your
                        email. Then you can login to your account. If you don't see the email, check your junk, spam,
                        social or other folders.
                    </p>
                </div>
            </div>

            <div className="w-1/2 shadow-2xl">
                <img
                    alt="company logo"
                    className="object-cover w-full h-screen hidden md:block"
                    src="https://images.pexels.com/photos/2129796/pexels-photo-2129796.png?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                />
            </div>
        </div>
    );
}
