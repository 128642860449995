import React, { useEffect } from 'react';
import GitInfo from 'react-git-info/macro';
import Moment from 'react-moment';
const gitInfo = GitInfo();

export default function Footer() {
    //const [backendHash, setBackendHash] = useState<string>('');
    //const [backendDate, setBackendDate] = useState<string>('');
    async function getAPIInformation() {
        //const [backendHashInfo, backendDateInfo] = await getAPIVersion();
        //setBackendHash(backendHashInfo);
        //setBackendDate(backendDateInfo);
    }
    useEffect(() => {
        getAPIInformation();
    }, []);
    return (
        <div className="bg-black -p-64">
            <div className="container mx-auto px-6 pt-10 pb-6 ">
                <div className="flex flex-wrap">
                    <div className="w-1/2 md:w-1/2 text-center md:text-left">
                        <h5 className="uppercase mb-6 font-bold text-white">Social</h5>
                        <ul className="mb-4">
                            <li className="mt-2">
                                <a
                                    href="https://www.linkedin.com/company/factionfinancial"
                                    className="hover:underline text-gray-400 hover:text-green-500"
                                >
                                    Linkedin
                                </a>
                            </li>
                            <li className="mt-2">
                                <a
                                    href="https://www.instagram.com/factionfinancial"
                                    className="hover:underline text-gray-400 hover:text-green-500"
                                >
                                    Instagram
                                </a>
                            </li>
                            <li className="mt-2">
                                <a
                                    href="https://twitter.com/factionfin"
                                    className="hover:underline text-gray-400 hover:text-green-500"
                                >
                                    Twitter
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="w-1/2 md:w-1/2 text-center md:text-left">
                        <h5 className="uppercase mb-6 font-bold text-white">Company</h5>
                        <ul className="mb-4">
                            {/* <li className="mt-2">
                                <a
                                    href="https://stats.uptimerobot.com/Kz3q8iq7yV"
                                    className="hover:underline text-gray-400 hover:text-green-500"
                                >
                                    Status
                                </a>
                            </li> */}
                            <li className="mt-2">
                                <a
                                    href="mailto:bugs@factionfinancial.com"
                                    className="hover:underline text-gray-400 hover:text-green-500"
                                >
                                    Report a bug
                                </a>
                            </li>
                            <li className="mt-2">
                                <a
                                    href="mailto:contact@factionfinancial.com"
                                    className="hover:underline text-gray-400 hover:text-green-500"
                                >
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="text-gray-500">Faction Financial Corporation 2020-2022</div>
                    <div className="text-gray-600 mt-1 jusitfy-end">
                        React build: {gitInfo.commit.shortHash} |{' '}
                        <Moment format=" Do MMMM, Y - h:mma ">{gitInfo.commit.date}</Moment>
                    </div>
                    {/* <div className="text-gray-600 mt-1 jusitfy-end">
                        API build: {backendHash} | <Moment format=" Do MMMM, Y - h:mma ">{backendDate}</Moment>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
