import React from 'react';
import nasdaqlogo from '../assets/images/nasdaqlogo50.png';
import nyselogo from '../assets/images/nyselogo50.png';
import tsxlogo from '../assets/images/tsxlogo50.png';
import { useGlobalState } from './Context';
import { deleteCredentials } from './CredentialManager';

export function getMinDate(): Date {
    return new Date(Date.now());
}

export function getMaxDate(): Date {
    const newDate = new Date(Date.now());
    newDate.setDate(newDate.getDate() + 21);
    return newDate;
}

export function getCurrency(exchange: string | undefined): string | undefined {
    switch (exchange) {
        case 'TSX':
            return 'CAD';
        case 'NAS':
            return 'USD';
        case 'NYS':
            return 'USD';
        default:
            return undefined;
    }
}
export function getExchangeLogo(exchange: string | undefined): string | undefined {
    switch (exchange) {
        case 'TSX':
            return tsxlogo;
        case 'NAS':
            return nasdaqlogo;
        case 'NYS':
            return nyselogo;
        default:
            return undefined;
    }
}

export const useAsyncError = () => {
    const [, setError] = React.useState();
    const [, dispatch] = useGlobalState();
    return React.useCallback(
        (e) => {
            deleteCredentials();
            dispatch({
                isAuthenticated: false,
                userToken: undefined,
                message: e,
            });
            setError(() => {
                throw e;
            });
        },
        [setError, dispatch]
    );
};
