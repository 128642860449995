import React, { useEffect } from 'react';
import { HeadProps } from '../..';

const defaultDocumentTitle = 'Faction Financial';
const defaultIconUrl = '%PUBLIC_URL%/factionfinancialwhite.jpg';
const defaultLinkDescription = 'AI run hedgefund that allows anyone to be a financial analyst.';
const defaultSiteName = 'https://factionfinancial.com/';

export default function Head(props: HeadProps) {
    document.title = props.title + ' | Faction Financial';
    if (props.iconURL !== undefined) {
        document.getElementById('ogImage')?.setAttribute('content', props.iconURL);
    }
    if (props.description !== undefined && document.getElementById('ogDescription') !== null) {
        document.getElementById('ogDescription')?.setAttribute('content', props.description);
    }
    document.getElementById('ogURL')?.setAttribute('content', window.location.href);
    document.getElementById('ogTitle')?.setAttribute('content', props.title + ' | Faction Financial');
    useEffect(() => {
        return () => {
            document.title = defaultDocumentTitle;
            document.getElementById('ogDescription')?.setAttribute('content', defaultLinkDescription);
            document.getElementById('ogImage')?.setAttribute('content', defaultIconUrl);
            document.getElementById('ogURL')?.setAttribute('content', defaultSiteName);
        };
        // eslint-disable-next-line
    }, []);
    return <React.Fragment />;
}
