import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useGlobalState } from '../../helper/Context';
import {
    getCompanyLogoURL,
    getHistoricalStockPrices,
    getMyTitle,
    getPredictionDetails,
} from '../../helper/DataServiceProvider';
import Head from '../Head';
import RecentPredictions from '../LandingPage/RecentPredictions';
import Loading from '../Loading';
import StaticNavbar from '../Navbar/StaticNavbar';
import PredictionCard from './PredictionCard/PredictionCard';

export default function PredictionDetailPage(props: { path: string }) {
    const [state] = useGlobalState();
    const [invalidPrediction, setInvalidPredictionState] = useState<boolean | undefined>(undefined);
    const [prediction, setPrediction] = useState<any>(undefined);
    const [actualPrice, setActualPrice] = useState<number | undefined>(undefined);
    const [logoUrl, setLogoUrl] = useState<any>(undefined);
    const [title, setTitle] = useState<string | undefined>(undefined);
    useEffect(() => {
        const getTitle = async function () {
            try {
                const title = await getMyTitle();
                console.log(title);
                if (title !== undefined) {
                    setTitle(title);
                } else {
                    console.log('cannot find title2');
                }
            } catch (error) {
                console.log('cannot find title');
            }
        };
        getTitle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let overlay = <React.Fragment />;
    let analystDescription = 'This prediction was submitted by a verified analyst';
    if (title !== 'analyst') {
        analystDescription += title;
    } else {
        analystDescription = '';
    }
    if (state.isAuthenticated !== true) {
        // display the component image to sign up
        overlay = (
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
                        <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-blue-300">
                            Hey, looks like you're not signed in. Want to earn money for your financial analysis?{' '}
                        </h1>
                        <a
                            href="/register"
                            className="flex-shrink-0 text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg mt-10 sm:mt-0"
                        >
                            Sign Up
                        </a>
                    </div>
                </div>
                <RecentPredictions />
            </section>
        );
    } else {
        overlay = (
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
                        <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-blue-300">
                            The 10 most shared predictions earn a $100 Amazon giftcard. Rewarded weekly on Friday.{' '}
                        </h1>
                    </div>
                </div>
            </section>
        );
    }
    const path = '/prediction/';
    var index = props.path.indexOf(path);
    const token = props.path.slice(0, index) + props.path.slice(index + path.length);
    useEffect(() => {
        getPredictionDetails(token)
            .then((prediction) => {
                console.log('link is still valid');
                setPrediction(prediction);
                setInvalidPredictionState(false);
            })
            .catch((error) => {
                setInvalidPredictionState(true);
                console.log('expired link ' + error.message);
            });
    }, [token]);
    useEffect(() => {
        const getHistoricalPrices = async function () {
            try {
                const prices = await getHistoricalStockPrices(
                    prediction.Ticker,
                    prediction.TargetDate,
                    prediction.TargetDate
                );
                const logoURL: any = await getCompanyLogoURL(prediction.Ticker);
                if (prices !== undefined && prices[0] !== undefined && prices[0].Close !== undefined) {
                    setActualPrice(prices[0].Close.Float64);
                }
                if (logoURL !== undefined && logoURL.length > 0) {
                    setLogoUrl(logoURL[0]._source.logo);
                }
            } catch (error) {
                console.log('cannot retrieve historicalPrices');
            }
        };
        // get the latest historicalPrices
        if (prediction !== undefined) {
            getHistoricalPrices();
        }
    }, [prediction]);
    let container: JSX.Element;
    if (invalidPrediction === true) {
        container = (
            <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 flex-grow min-h-screen">
                <div className="sm:text-center lg:text-left">
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        Your prediction details link is not valid.
                    </p>
                    <p>
                        <a href="/login" className="underline font-semibold">
                            Go back to Login Page
                        </a>
                    </p>
                    <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"></p>
                </div>
            </div>
        );
    } else if (prediction !== undefined) {
        container = (
            <React.Fragment>
                <Head
                    title={prediction.Ticker}
                    description={'Stock prediction for ' + prediction.Exchange + ':' + prediction.Ticker}
                    iconURL={logoUrl}
                />
                <PredictionCard
                    id={prediction.ID}
                    detailed={true}
                    interactable={false}
                    exchange={prediction.Exchange}
                    targetDate={prediction.TargetDate}
                    targetPrice={prediction.TargetPrice}
                    priceAtCreation={prediction.PriceAtCreation}
                    timeAtCreation={prediction.TimeAtCreation}
                    accuracy={prediction.Accuracy}
                    dayDifference={prediction.DayDifference}
                    percentageChange={prediction.PercentageChange}
                    correctDirection={prediction.CorrectDirection}
                    name={prediction.Name}
                    ticker={prediction.Ticker}
                    comments={prediction.Comments}
                />
            </React.Fragment>
        );
    } else {
        container = <Loading />;
    }

    return (
        <motion.div
            className="flex flex-col"
            animate={{
                opacity: [0, 1],
            }}
            transition={{
                duration: 2,
                ease: 'easeIn',
                times: [0, 0.3],
            }}
        >
            <StaticNavbar />
            <section className="text-gray-600 body-font overflow-hidden">
                <div className="container px-5 py-24 mx-auto">
                    <div className="lg:w-4/5 mx-auto flex flex-wrap">
                        {
                            <div className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded">
                                {container}
                            </div>
                        }
                        {prediction && (
                            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-12 lg:mt-0">
                                <h2 className="text-sm title-font text-gray-400 tracking-widest">
                                    {prediction.Exchange}:{prediction.Ticker}
                                </h2>
                                <h1 className="text-gray-300 text-3xl title-font font-medium mb-1">
                                    {prediction.Name}
                                </h1>
                                <h1 className="text-white">{analystDescription}</h1>
                                <div className="flex mb-4">
                                    <span className="flex py-2 space-x-6 text-gray-500">
                                        <a
                                            href={
                                                'https://twitter.com/intent/tweet?url=https://factionfinancial.com' +
                                                props.path
                                            }
                                        >
                                            <svg
                                                fill="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                className="w-10 h-10  hover:text-gray-200"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                                            </svg>
                                        </a>
                                        <a
                                            href={
                                                'https://www.reddit.com/submit?url=https://factionfinancial.com' +
                                                props.path
                                            }
                                        >
                                            <svg
                                                fill="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                className="w-10 h-10  hover:text-gray-200"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M24 11.779c0-1.459-1.192-2.645-2.657-2.645-.715 0-1.363.286-1.84.746-1.81-1.191-4.259-1.949-6.971-2.046l1.483-4.669 4.016.941-.006.058c0 1.193.975 2.163 2.174 2.163 1.198 0 2.172-.97 2.172-2.163s-.975-2.164-2.172-2.164c-.92 0-1.704.574-2.021 1.379l-4.329-1.015c-.189-.046-.381.063-.44.249l-1.654 5.207c-2.838.034-5.409.798-7.3 2.025-.474-.438-1.103-.712-1.799-.712-1.465 0-2.656 1.187-2.656 2.646 0 .97.533 1.811 1.317 2.271-.052.282-.086.567-.086.857 0 3.911 4.808 7.093 10.719 7.093s10.72-3.182 10.72-7.093c0-.274-.029-.544-.075-.81.832-.447 1.405-1.312 1.405-2.318zm-17.224 1.816c0-.868.71-1.575 1.582-1.575.872 0 1.581.707 1.581 1.575s-.709 1.574-1.581 1.574-1.582-.706-1.582-1.574zm9.061 4.669c-.797.793-2.048 1.179-3.824 1.179l-.013-.003-.013.003c-1.777 0-3.028-.386-3.824-1.179-.145-.144-.145-.379 0-.523.145-.145.381-.145.526 0 .65.647 1.729.961 3.298.961l.013.003.013-.003c1.569 0 2.648-.315 3.298-.962.145-.145.381-.144.526 0 .145.145.145.379 0 .524zm-.189-3.095c-.872 0-1.581-.706-1.581-1.574 0-.868.709-1.575 1.581-1.575s1.581.707 1.581 1.575-.709 1.574-1.581 1.574z"></path>
                                            </svg>
                                        </a>

                                        <svg
                                            fill="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            className="w-10 h-10 hover:text-gray-200 cursor-pointer"
                                            viewBox="0 0 24 24"
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    'https://factionfinancial.com' + props.path
                                                );
                                            }}
                                        >
                                            <path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z"></path>
                                        </svg>
                                    </span>
                                </div>
                                <p className="leading-relaxed text-gray-300">{prediction.Comments}</p>
                                <div className="flex-col flex bottom-0 mt-4">
                                    <span className="text-gray-400">Reference</span>
                                    <span className=" font-medium text-2xl text-gray-300 ">
                                        <div className="texl-2xl inline">${prediction.PriceAtCreation}</div>{' '}
                                        <div className="text-gray-400 inline text-base">on</div>{' '}
                                        <div className="inline text-base">
                                            <Moment format="MMMM DD, YYYY">{prediction.TimeAtCreation}</Moment> at{' '}
                                            <Moment format="h:mm a">{prediction.TimeAtCreation}</Moment>
                                        </div>
                                    </span>
                                    <span className="text-gray-400 mt-4">Predicted</span>
                                    <span className=" font-medium text-2xl text-gray-300 ">
                                        <div className="texl-2xl inline">${prediction.TargetPrice}</div>{' '}
                                        <div className="text-gray-400 inline text-base">on</div>{' '}
                                        <div className="inline text-base">
                                            <Moment format="MMMM DD, YYYY">{prediction.TargetDate}</Moment>
                                        </div>
                                    </span>
                                    {actualPrice && (
                                        <React.Fragment>
                                            <span className="text-gray-400 mt-4">Actual</span>
                                            <span className=" font-medium text-2xl text-gray-300 ">
                                                <div className="texl-2xl">${actualPrice}</div>{' '}
                                            </span>
                                            <span className="text-gray-400 mt-4">Difference</span>
                                            <span className=" font-medium text-2xl text-gray-300 ">
                                                <div className="texl-2xl">
                                                    ${(actualPrice - prediction.TargetPrice).toFixed(2)}
                                                </div>{' '}
                                            </span>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        )}{' '}
                        {overlay}
                    </div>
                </div>
            </section>
        </motion.div>
    );
}
