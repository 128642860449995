import React, { useState } from 'react';
import FFlogo from '../../assets/images/factionfinancialwhite.png';
import { useGlobalState } from '../../helper/Context';
import AboutDropdown from './AboutDropdown';

export default function StaticNavbar() {
    const [state] = useGlobalState();
    const [mobileMenu, showMobileMenu] = useState(false);
    const [firstName] = useState('');
    const [lastName] = useState('');
    let navigationMenu: JSX.Element = (
        <div>
            <div className={mobileMenu ? 'block' : 'flex'}>
                <a href="/login">
                    <button className="flex text-sm border-2 border-transparent transition focus:outline-none duration-150 ease-in-out">
                        <p className="px-3 py-2 rounded-md text-xl  font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">
                            Login
                        </p>
                    </button>
                </a>
                <a href="/register">
                    <button className="flex text-sm border-2 border-transparent transition focus:outline-none duration-150 ease-in-out">
                        <p className="px-3 py-2 rounded-md text-xl bg-blue-600 font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">
                            Register
                        </p>
                    </button>
                </a>
            </div>
        </div>
    );
    let navBarLogo = (
        <a href="/home">
            <img alt="Faction Financial Logo" className="h-10 invisible sm:visible" src={FFlogo}></img>
        </a>
    );
    if (state.isAuthenticated) {
        navBarLogo = (
            <a href="/dashboard">
                <img alt="Faction Financial Logo" className="h-10 invisible sm:visible" src={FFlogo}></img>
            </a>
        );
        navigationMenu = (
            <div>
                <div className="flex">
                    <a href="/dashboard">
                        <button className="flex text-sm border-2 border-transparent transition focus:outline-none duration-150 ease-in-out">
                            <p className="px-3 py-2 rounded-md text-xl font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">
                                My Account
                            </p>
                        </button>
                    </a>
                </div>
            </div>
        );
    }
    return (
        <nav className="fixed w-full z-50 bg-black  ">
            <div className=" mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        {/* <!-- Mobile menu button--> */}
                        <button
                            onClick={() => {
                                showMobileMenu(!mobileMenu);
                            }}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
                        >
                            {/* <!-- Icon when menu is closed. --> */}
                            {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
                            <svg className="block h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                            {/* <!-- Icon when menu is open. --> */}
                            {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
                            <svg className="hidden h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        {navBarLogo}
                    </div>{' '}
                    {navBarLogo}
                    <div className="flex-1 flex items-center justify-end sm:items-stretch ">
                        <div className="flex-shrink-0">
                            <img
                                className="block lg:hidden h-8 w-auto"
                                src="/img/logos/workflow-mark-on-dark.svg"
                                alt=""
                            />
                            <img
                                className="hidden lg:block h-8 w-auto"
                                src="/img/logos/workflow-logo-on-dark.svg"
                                alt=""
                            />
                        </div>
                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex items-center justify-between">
                                <a href="/unauthrecom">
                                    <button className="flex text-sm border-2 border-transparent transition focus:outline-none duration-150 ease-in-out">
                                        <p className="px-3 py-2 rounded-md text-xl  font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">
                                            AI Recommendations
                                        </p>
                                    </button>
                                </a>
                                <a href="/about">
                                    <AboutDropdown firstName={firstName} lastName={lastName} />
                                </a>
                                {/* <a
                                    href="https://blog.factionfinancial.com/"
                                    className={
                                        'px-3 py-2 rounded-md text-xl  font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                    }
                                >
                                    Blog
                                </a> */}
                                {navigationMenu}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--
    Mobile menu, toggle classNames based on menu state.

    Menu open: "block", Menu closed: "hidden"
  --> */}
            <div className={mobileMenu ? 'block sm:hidden' : 'hidden sm:hidden'}>
                <div className="m-1 flex-col">
                    <div className="py-3">
                        <a href="/about">
                            <AboutDropdown firstName={firstName} lastName={lastName} />
                        </a>
                    </div>
                    <div className="py-3">
                        {/* <a
                            href="https://blog.factionfinancial.com/"
                            className={
                                'px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                            }
                        >
                            Blog
                        </a> */}
                        {navigationMenu}
                    </div>
                </div>
            </div>
        </nav>
    );
}
