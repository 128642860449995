import { Rank } from '../..';
import nasdaqlogo from '../assets/images/nasdaqlogo200.png';
import nyselogo from '../assets/images/nyselogo200.png';
import tsxlogo from '../assets/images/tsxlogo200.png';

export function getExchangeLogoURL(exchange: string | undefined): string | undefined {
    switch (exchange) {
        case 'TSX':
            return tsxlogo;
        case 'NAS':
            return nasdaqlogo;
        case 'NYS':
            return nyselogo;
        default:
            return undefined;
    }
}

export function getRankInformation(rating: number): Rank {
    if (rating < 2000) {
        // bronze
        return {
            level: 'Bronze',
            nextRank: 'Silver',
            levelCeiling: 1999,
            colorStop1: '#6D6027',
            colorStop2: '#D3CBB8',
        };
    } else if (rating < 3000) {
        // silver
        return {
            level: 'Silver',
            nextRank: 'Gold',
            levelCeiling: 2999,
            colorStop1: '#C9D6FF',
            colorStop2: '#E2E2E2',
        };
    } else if (rating < 4000) {
        // gold
        return {
            level: 'Gold',
            nextRank: 'Platinum',
            levelCeiling: 3999,
            colorStop1: '#F2994A',
            colorStop2: '#F2C94C',
        };
    } else if (rating < 5000) {
        // platinum
        return {
            level: 'Platinum',
            nextRank: 'Diamond',
            levelCeiling: 4999,
            colorStop1: '#74ebd5',
            colorStop2: '#ACB6E5',
        };
    } else {
        // diamond
        return { level: 'Diamond', colorStop1: '#a8c0ff', colorStop2: '#3f2b96' };
    }
}
