import React, { useEffect, useState } from 'react';
import { verifyAccount } from '../../helper/DataServiceProvider';
import Loading from '../Loading';
import StaticNavbar from '../Navbar/StaticNavbar';

export default function EmailVerificationPage(props: { path: string }) {
    const [expiredVerificationState, setExpiredVerificationState] = useState<boolean | undefined>(undefined);
    const path = '/verify-email/';
    var index = props.path.indexOf(path);
    const token = props.path.slice(0, index) + props.path.slice(index + path.length);
    useEffect(() => {
        verifyAccount(token)
            .then(() => {
                setExpiredVerificationState(false);
                console.log('link is still valid');
            })
            .catch((error) => {
                setExpiredVerificationState(true);
                console.log('expired link ' + error.message);
            });
    }, [token]);
    let content = undefined;
    if (expiredVerificationState === undefined) {
        return (
            <div className="flex h-screen bg-black ">
                <div className="w-1/4 m-auto">
                    <Loading />
                </div>
            </div>
        );
    }
    if (expiredVerificationState) {
        content = (
            <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 flex-grow min-h-screen">
                <div className="sm:text-center lg:text-left">
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        Your account verification link is expired or not valid. Please re-login to get a new link.
                    </p>
                    <p>
                        <a href="/login" className="underline font-semibold">
                            Go back to Login Page
                        </a>
                    </p>
                    <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"></p>
                    <div></div>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 flex-grow">
                <div className="sm:text-center lg:text-left">
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        Your account has been verified. Please login!
                    </p>
                    <p>
                        <a href="/login" className="underline font-semibold">
                            Go back to Login Page
                        </a>
                    </p>
                    <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"></p>
                    <div></div>
                </div>
            </div>
        );
    }
    return (
        <div className="flex flex-col min-h-screen">
            <StaticNavbar />
            {content}
        </div>
    );
}
