import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { getPredictionsCount } from '../../helper/DataServiceProvider';
import { useAsyncError } from '../../helper/util';
import PredictionCardContainer from '../Prediction/PredictionCard/PredictionCardContainer';
import PredictionForm from '../Prediction/PredictionForm/PredictionForm';

export default function Dashboard() {
    const MAX_CARDS_PER_PAGE = 5;
    const [totalPredictionCount, setPredictionCount] = useState<number | undefined>(undefined);
    const throwError = useAsyncError();
    const updatePredictionCount = async function () {
        try {
            const totalCount = await getPredictionsCount();
            if (totalCount !== undefined) {
                setPredictionCount(totalCount);
            }
        } catch (error) {
            throwError(error);
        }
    };
    useEffect(() => {
        let isMounted = true; // note this flag denote mount status
        getPredictionsCount().then((count) => {
            if (isMounted && count !== undefined) {
                setPredictionCount(count);
            }
        });
        return () => {
            isMounted = false;
        }; // use effect cleanup to set flag false, if unmounted
    }, []);
    return (
        <div className="flex-1 mt-16 bg-black">
            <div className="flex justify-center flex-wrap-reverse ">
                <div className="rounded overflow-hidden min-w-full md:min-w-0 w-5/6 128 max-w-2xl px-6 py-4 ">
                    <PredictionCardContainer
                        detailed={false}
                        totalPredictionCount={totalPredictionCount}
                        filter={undefined}
                        maxCardsPerPage={MAX_CARDS_PER_PAGE}
                    />
                </div>
                <motion.div
                    animate={{
                        opacity: [0, 1],
                        y: [-20, 0],
                    }}
                    transition={{
                        duration: 2,
                        ease: 'easeIn',
                        times: [0, 0.2],
                    }}
                    className="rounded justify-center  px-6 py-4 "
                >
                    <PredictionForm updatePredictionCount={updatePredictionCount} />
                </motion.div>
            </div>
        </div>
    );
}
