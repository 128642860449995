import { motion } from 'framer-motion';
import React from 'react';
import exampleVideo from '../../assets/images/exampleVideo.mp4';
import bgimage from '../../assets/images/factionlandingbackground.jpg';
import StaticNavbar from '../Navbar/StaticNavbar';
import LandingSignUp from './LandingSignUp';
import RecentPredictions from './RecentPredictions';

export default function LandingPage() {
    return (
        <div className="flex flex-col ">
            <StaticNavbar />
            <motion.div
                id="hero"
                className="h-full w-full "
                style={{
                    backgroundImage: `url(${bgimage})`,
                    backgroundColor: `rgba(0, 0, 0, 0.5)`,
                }}
                animate={{
                    opacity: [0, 1],
                }}
                transition={{
                    duration: 1,
                    ease: 'easeIn',
                    times: [0, 0.5],
                }}
            >
                <div className="flex flex-row flex-wrap justify-evenly pt-64 mx-16 md:mx-32 xl:mx-64 min-h-screen">
                    <div>
                        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-300 sm:text-5xl sm:leading-none md:text-6xl ">
                            Earn $ on&nbsp;<br></br>
                            <span className="text-blue-600">Stock Predictions</span>
                        </h2>
                        <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 ">
                            High ranked users earn top dollar
                        </p>
                        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                            <div className="rounded-md shadow">
                                <a
                                    href="/about"
                                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                                >
                                    See How
                                </a>
                            </div>
                        </div>
                    </div>
                    <LandingSignUp />
                    <RecentPredictions />
                </div>
            </motion.div>
            <div>
                <section className="text-gray-600 body-font">
                    <div className="container px-5 py-24 mx-auto flex flex-wrap">
                        <div className="flex flex-wrap w-full">
                            <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
                                <div className="flex relative pb-12">
                                    <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                    </div>
                                    <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative z-10">
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            className="w-5 h-5"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <motion.div
                                        className="flex-grow pl-4"
                                        animate={{
                                            opacity: [0, 1],
                                        }}
                                        transition={{
                                            duration: 3,
                                            ease: 'easeIn',
                                            times: [0, 0.2],
                                        }}
                                    >
                                        <h2 className="font-medium title-font text-sm text-gray-500 mb-1 tracking-wider">
                                            STEP 1
                                        </h2>
                                        <p className="leading-relaxed text-gray-300">Research a company</p>
                                    </motion.div>
                                </div>
                                <div className="flex relative pb-12">
                                    <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                    </div>
                                    <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative z-10">
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            className="w-5 h-5"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                        </svg>
                                    </div>
                                    <motion.div
                                        className="flex-grow pl-4"
                                        animate={{
                                            opacity: [0, 1],
                                        }}
                                        transition={{
                                            duration: 4,
                                            ease: 'easeIn',
                                            times: [0, 0.2],
                                        }}
                                    >
                                        <h2 className="font-medium title-font text-sm text-gray-500 mb-1 tracking-wider">
                                            STEP 2
                                        </h2>
                                        <p className="leading-relaxed text-gray-300">
                                            Enter Target Price and Date based on your analysis and submit
                                        </p>
                                    </motion.div>
                                </div>

                                <div className="flex relative">
                                    <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative z-10">
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            className="w-5 h-5"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                            <path d="M22 4L12 14.01l-3-3"></path>
                                        </svg>
                                    </div>
                                    <motion.div
                                        className="flex-grow pl-4"
                                        animate={{
                                            opacity: [0, 1],
                                        }}
                                        transition={{
                                            duration: 7,
                                            ease: 'easeIn',
                                            times: [0, 0.2],
                                        }}
                                    >
                                        <h2 className="font-medium title-font text-sm text-gray-400 mb-1 tracking-wider">
                                            FINISH
                                        </h2>
                                        <p className="leading-relaxed text-gray-300">
                                            Our AI will evaluate your prediction, and you'll gain or lose rank depending
                                            on performance.
                                        </p>
                                    </motion.div>
                                </div>
                            </div>
                            <video
                                width="1280"
                                height="720"
                                autoPlay={false}
                                muted={true}
                                loop={true}
                                className="lg:w-3/5 md:w-1/2  object-center rounded-lg md:mt-0 mt-12"
                                controls
                            >
                                <source src={exampleVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
