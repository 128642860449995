import { curveMonotoneX } from '@vx/curve';
import { localPoint } from '@vx/event';
import { LinearGradient } from '@vx/gradient';
import { scaleLinear, scaleTime } from '@vx/scale';
import { AreaClosed, Bar, Line } from '@vx/shape';
import { defaultStyles, Tooltip } from '@vx/tooltip';
import { bisector, extent, max, min } from 'd3-array';
//@ts-ignore
import { timeFormat } from 'd3-time-format';
import { motion } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import { HistoricalPrice, PredictionCardGraphProps } from '../../../..';
import { getHistoricalStockPrices } from '../../../helper/DataServiceProvider';

export const background = '#f7fafc';
export const background2 = '#1a202c';
export const accentColor = '#a0aec0';
const formatDate = timeFormat("%b %d, '%y");
const bisectDate = bisector<HistoricalPrice, Date>(
    (historicalPrice: HistoricalPrice) => new Date(historicalPrice.Date)
).left;

const getDate = (price: HistoricalPrice) => new Date(price.Date);
const getPrice = (price: HistoricalPrice) => price.Close.Float64;
export default function PredictionCardGraph(props: PredictionCardGraphProps) {
    const [historicalPrices, setHistoricalPrices] = useState<Array<HistoricalPrice>>([]);
    const [toolTipData, setToolTipData] = useState<HistoricalPrice | undefined>(undefined);
    const [toolTipLeft, setToolTipLeft] = useState<number | undefined>(undefined);
    const [toolTipTop, setToolTipTop] = useState<number | undefined>(undefined);
    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const xMax = props.width - margin.left - margin.right;
    const yMax = props.height - margin.top - margin.bottom;

    useEffect(() => {
        let isCancelled = false;
        const getHistoricalPrices = async function () {
            try {
                const prices = await getHistoricalStockPrices(props.ticker, props.minDate, props.maxDate);
                if (prices !== undefined && !isCancelled) {
                    setHistoricalPrices(prices);
                }
            } catch (error) {
                console.log('cannot retrieve historicalPrices');
            }
        };
        // reset historical prices
        setHistoricalPrices([]);
        // get the latest historicalPrices
        getHistoricalPrices();
        return () => {
            isCancelled = true;
        };
    }, [props.ticker, props.minDate, props.maxDate]);
    const getHistoricalPriceDate = (price: HistoricalPrice) => new Date(price.Date);
    const getHistoricalPriceStockValue = (price: HistoricalPrice) => price.Close.Float64;
    // scales
    const dateScale = useMemo(
        () =>
            scaleTime({
                range: [0, xMax],
                domain: extent(historicalPrices, getHistoricalPriceDate) as [Date, Date],
            }),
        [xMax, historicalPrices]
    );
    const stockValueScale = useMemo(
        function () {
            let minimumPriceDisplay = Math.min(
                min(historicalPrices, getHistoricalPriceStockValue) || 0,
                Math.ceil(props.targetPrice),
                Math.ceil(props.priceAtCreation)
            );
            if (minimumPriceDisplay) {
                minimumPriceDisplay -= 0.25 * minimumPriceDisplay;
            }
            return scaleLinear({
                range: [yMax, 0],
                domain: [
                    minimumPriceDisplay,
                    Math.max(
                        max(historicalPrices, getHistoricalPriceStockValue) || 0,
                        Math.ceil(props.priceAtCreation),
                        Math.ceil(props.targetPrice)
                    ),
                ],
                nice: true,
            });
        },
        [yMax, historicalPrices, props.priceAtCreation, props.targetPrice]
    );

    // tooltip handler
    const handleTooltip = (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        if (historicalPrices === undefined || historicalPrices.length <= 1) {
            return;
        }
        const { x } = localPoint(event) || { x: 0 };
        const x0 = dateScale.invert(x);
        const index = bisectDate(historicalPrices, x0, 1);
        const d0 = historicalPrices[index - 1];
        const d1 = historicalPrices[index];
        let d = d0;
        if (d1 && getDate(d1)) {
            d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
        }
        if (d && x) {
            setToolTipData(d);
            setToolTipLeft(x);
            setToolTipTop(stockValueScale(getPrice(d)));
        }
    };

    return (
        <motion.div
            className="absolute"
            animate={{
                opacity: [0, 1],
            }}
            transition={{
                duration: 5,
                ease: 'easeIn',
                times: [0, 0.2],
            }}
        >
            <svg width={xMax} height={yMax}>
                <rect x={0} y={0} width={xMax} height={yMax} fill="url(#area-background-gradient)" />
                <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={0.1} />

                <AreaClosed<HistoricalPrice>
                    data={historicalPrices}
                    strokeWidth={1}
                    x={(historicalPrice) => dateScale(getHistoricalPriceDate(historicalPrice))!}
                    y={(historicalPrice) => stockValueScale(getHistoricalPriceStockValue(historicalPrice))!}
                    yScale={stockValueScale}
                    stroke="url(#area-gradient)"
                    fill="url(#area-gradient)"
                    curve={curveMonotoneX}
                />
                <Bar
                    x={0}
                    y={0}
                    width={xMax}
                    height={yMax}
                    fill="transparent"
                    onTouchStart={handleTooltip}
                    onTouchMove={handleTooltip}
                    onMouseMove={handleTooltip}
                    onMouseLeave={() => {
                        setToolTipData(undefined);
                        setToolTipLeft(undefined);
                        setToolTipTop(undefined);
                    }}
                />
                <Line
                    from={{ x: 0, y: stockValueScale(props.priceAtCreation) }}
                    to={{ x: xMax, y: stockValueScale(props.targetPrice) }}
                    stroke={accentColor}
                />
            </svg>
            {toolTipData && toolTipLeft && toolTipTop && (
                <div>
                    <Tooltip top={toolTipTop - 50} left={toolTipLeft - 40}>
                        {`$${getPrice(toolTipData)}`}
                    </Tooltip>
                    <Tooltip
                        top={yMax - 14}
                        left={toolTipLeft}
                        style={{
                            ...defaultStyles,
                            width: 72,
                            textAlign: 'center',
                            transform: 'translateX(-50%)',
                        }}
                    >
                        {formatDate(getDate(toolTipData))}
                    </Tooltip>
                </div>
            )}
        </motion.div>
    );
}
