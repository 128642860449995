import React from 'react';
import RecentPredictions from '../LandingPage/RecentPredictions';
export default function UnauthRecom() {
    return (
        <div>
            <div className="d-flex p-2 mt-12 justify-content-between">
                <a href="/register" className="">
                    <div className="bg-blue-600 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-12 rounded text-center m-auto w-64">
                        Create New Account
                    </div>
                </a>
                <a href="/login" className="">
                    <div className="bg-blue-600 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-12 rounded text-center m-auto w-64">
                        Login
                    </div>
                </a>
            </div>
            <div className="flex-1 mt-16 bg-black blur-sm">
                <RecentPredictions />
            </div>
        </div>
    );
}
