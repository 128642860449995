import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import AuthRecom from './Airecom/Authrecom';
import UnauthRecom from './Airecom/Unauthrecom';
import Footer from './Footer';
import Head from './Head';
import About from './LandingPage/About';
import LandingPage from './LandingPage/LandingPage';
import ForgotPasswordPage from './LoginForm/ForgotPasswordPage';
import LoginFormContainer from './LoginForm/LoginFormContainer';
import PasswordResetPage from './LoginForm/PasswordResetPage';
import Navbar from './Navbar/Navbar';
import StaticNavbar from './Navbar/StaticNavbar';
import PredictionDetailPage from './Prediction/PredictionDetailPage';
import EmailVerificationPage from './Register/EmailVerificationPage.';
import RegisterFormContainer from './Register/RegisterFormContainer';
import ScrollToTop from './ScrollToTop';
import { SignUpRoute } from './SignUpRoute';
import BestAnalyst from './Tab/BestAnalyst';
import Dashboard from './Tab/Dashboard';
import History from './Tab/History';
import NotFound from './Tab/NotFound';
import Profile from './Tab/Profile';
import Setting from './Tab/Setting';

export enum Page {
    DASHBOARD,
    HISTORY,
    PROFILE,
    SETTING,
    ABOUT,
    NOTFOUND,
    BESTANALYST,
    AUTHRECOM,
    UNAUTHRECOM,
}

export function Container(props: { currentPage: Page }) {
    let childView: JSX.Element = <></>;
    let navBar: JSX.Element = <></>;
    let title: string = '';
    if (props.currentPage === Page.DASHBOARD) {
        navBar = <Navbar currentPage={props.currentPage} />;
        childView = <Dashboard />;
        title = 'Dashboard';
    }
    if (props.currentPage === Page.HISTORY) {
        navBar = <Navbar currentPage={props.currentPage} />;
        childView = <History />;
        title = 'History';
    }
    if (props.currentPage === Page.PROFILE) {
        navBar = <Navbar currentPage={props.currentPage} />;
        childView = <Profile />;
        title = 'Profile';
    }
    if (props.currentPage === Page.SETTING) {
        navBar = <Navbar currentPage={props.currentPage} />;
        childView = <Setting />;
        title = 'Setting';
    }
    if (props.currentPage === Page.BESTANALYST) {
        navBar = <Navbar currentPage={props.currentPage} />;
        childView = <BestAnalyst />;
        title = 'Best Analysts';
    }
    if (props.currentPage === Page.AUTHRECOM) {
        navBar = <Navbar currentPage={props.currentPage} />;
        childView = <AuthRecom />;
        title = 'AuthRecom';
    }
    if (props.currentPage === Page.UNAUTHRECOM) {
        navBar = <StaticNavbar />;
        childView = <UnauthRecom />;
        title = 'UnauthRecom';
    }
    if (props.currentPage === Page.NOTFOUND) {
        navBar = <StaticNavbar />;
        childView = <NotFound />;
        title = 'Page Not Found';
    }
    if (props.currentPage === Page.ABOUT) {
        navBar = <StaticNavbar />;
        childView = <About />;
        title = 'About';
    }

    return (
        <React.Fragment>
            <Head title={title} />
            {navBar}
            {childView}
        </React.Fragment>
    );
}
export default class Routes extends Component<{ isAuthenticated: boolean }, {}> {
    render() {
        return (
            <div className="flex flex-col h-full min-h-screen bg-black" id="Base">
                <BrowserRouter>
                    <ScrollToTop />
                    <Switch>
                        // Common routes, should work regardless of authentication status
                        <Route exact={true} path="/home">
                            <LandingPage />
                        </Route>
                        <Route
                            exact={true}
                            path="/password-reset/*"
                            render={(props) => <PasswordResetPage path={props.location.pathname} />}
                        />
                        <Route
                            exact={true}
                            path="/verify-email/*"
                            render={(props) => <EmailVerificationPage path={props.location.pathname} />}
                        />
                        <Route
                            exact={true}
                            path="/prediction/*"
                            render={(props) => <PredictionDetailPage path={props.location.pathname} />}
                        />
                        <Route exact={true} path="/forgot-password">
                            <ForgotPasswordPage />
                        </Route>
                        <Route
                            exact={true}
                            path="/unauthrecom"
                            render={() => <Container currentPage={Page.UNAUTHRECOM} />}
                        ></Route>
                        <Route exact={true} path="/about" render={() => <Container currentPage={Page.ABOUT} />}></Route>
                        // Unauthenticated routes, should only work if unauthenticated, otherwise redirect
                        <SignUpRoute exact={true} path="/register" component={RegisterFormContainer} />
                        <Route exact={true} path="/login">
                            {this.props.isAuthenticated ? <Redirect to="/dashboard" /> : <LoginFormContainer />}
                        </Route>
                        // Authenticated routes, should only work if authenticated, otherwise redirect // they need to
                        be immediate children in order to redirect instantly
                        <Route
                            exact={true}
                            path={'/dashboard'}
                            render={({ location }) =>
                                this.props.isAuthenticated ? (
                                    <Container currentPage={Page.DASHBOARD} />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: location },
                                        }}
                                    />
                                )
                            }
                        />
                        <Route
                            exact={true}
                            path={'/history'}
                            render={({ location }) =>
                                this.props.isAuthenticated ? (
                                    <Container currentPage={Page.HISTORY} />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: location },
                                        }}
                                    />
                                )
                            }
                        />
                        <Route
                            exact={true}
                            path={'/profile'}
                            render={({ location }) =>
                                this.props.isAuthenticated ? (
                                    <Container currentPage={Page.PROFILE} />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: location },
                                        }}
                                    />
                                )
                            }
                        />
                        <Route
                            exact={true}
                            path={'/bestanalyst'}
                            render={({ location }) =>
                                this.props.isAuthenticated ? (
                                    <Container currentPage={Page.BESTANALYST} />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: location },
                                        }}
                                    />
                                )
                            }
                        />
                        <Route
                            exact={true}
                            path={'/setting'}
                            render={({ location }) =>
                                this.props.isAuthenticated ? (
                                    <Container currentPage={Page.SETTING} />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: location },
                                        }}
                                    />
                                )
                            }
                        />
                        <Route
                            exact={true}
                            path={'/'}
                            render={({ location }) =>
                                this.props.isAuthenticated ? (
                                    <Container currentPage={Page.DASHBOARD} />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: '/home',
                                            state: { from: location },
                                        }}
                                    />
                                )
                            }
                        />
                        <Route
                            exact={true}
                            path={'/authrecom'}
                            render={({ location }) =>
                                this.props.isAuthenticated ? (
                                    <Container currentPage={Page.AUTHRECOM} />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: location },
                                        }}
                                    />
                                )
                            }
                        />
                        <Route exact={true} path="*" render={() => <Container currentPage={Page.NOTFOUND} />}></Route>
                    </Switch>
                </BrowserRouter>
                <Footer />
            </div>
        );
    }
}
