import React from 'react';
import { LoginFormProps } from '../../../index';
import FFlogo from '../../assets/images/factionfinancialwhite.png';
import loginpic1 from '../../assets/images/loginpic1.jpg';
import loginpic2 from '../../assets/images/loginpic2.jpg';
import loginpic3 from '../../assets/images/loginpic3.jpg';
import loginpic4 from '../../assets/images/loginpic4.jpg';
import loginpic5 from '../../assets/images/loginpic5.jpeg';
import gorilla from '../../assets/images/gorilla.jpg';

export default function LoginForm(props: LoginFormProps) {
    return (
        <div className="w-full flex flex-wrap bg-black">
            <div className="w-full md:w-1/2 flex flex-col">
                <div className="flex justify-center px-32 pt-16 xl:w-3/4 overflow-hidden">
                    <a href="/">
                        <img alt="Faction Financial Logo" className="w-full h-1/2" src={FFlogo}></img>
                    </a>
                </div>
                <div className="flex flex-col justify-center md:justify-start  md:pt-0 px-8 md:px-24 lg:px-32">
                    <p className="text-center text-3xl text-white">Welcome Back.</p>
                    <form className="flex flex-col pt-3 md:pt-8" id="loginForm" onSubmit={props.onSubmitLogin}>
                        <div className="flex flex-col pt-4">
                            <input
                                required={true}
                                type="email"
                                id="email"
                                placeholder="your@email.com"
                                className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-300 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        <div className="flex flex-col pt-4">
                            <input
                                required={true}
                                type="password"
                                id="password"
                                placeholder="Password"
                                className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-300 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        {props.loginFail && (
                            <div
                                className="mt-10 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                role="alert"
                            >
                                <span className="block sm:inline">{props.loginFailMessage}</span>
                                <span className="absolute top-0 bottom-0 right-0 px-4 py-3" />
                            </div>
                        )}
                        <input
                            type="submit"
                            value="Log In"
                            className="bg-gray-900 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8 rounded"
                        />{' '}
                        <a
                            href="/forgot-password"
                            className="text-white font-bold text-sm hover:bg-gray-700 p-2 mt-4 rounded text-center"
                        >
                            Forgot Password?
                        </a>
                    </form>
                    <br></br>
                    <hr></hr>
                    <a href="/register" className="">
                        <div className="bg-gray-800 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-12 rounded text-center m-auto w-64">
                            Create New Account
                        </div>
                    </a>
                </div>
            </div>
            <div className="w-1/2 ">
                <img alt="company logo" className="object-cover w-full h-screen hidden md:block" src={getPic()} />
            </div>
        </div>
    );
}

function getPic() {
    const myImg = [loginpic1, loginpic2, loginpic3, loginpic4, loginpic5];
    const myGor = Math.floor(Math.random() * 100);
    if (myGor === 0) {
        return gorilla;
    } else {
        return myImg[Math.floor(Math.random() * myImg.length)];
    }
}
