import { Credential } from '../../index';

export function getCredentials(): Credential {
    const accountStorage = localStorage.getItem('account');
    if (accountStorage !== null) {
        const account: Credential = JSON.parse(accountStorage);
        return {
            token: account.token,
        };
    } else {
        throw new Error('Credentials do not exist locally. Invalid call');
    }
}

export function setCredentials(account: Credential): void {
    localStorage.setItem('account', JSON.stringify(account));
}

export function deleteCredentials(): void {
    localStorage.removeItem('account');
}

export function containsToken(): boolean {
    const accountStorage = localStorage.getItem('account');
    try {
        return accountStorage !== null && JSON.parse(accountStorage).token !== null;
    } catch {
        deleteCredentials();
        return false;
    }
}
