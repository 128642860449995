import React, { useState } from 'react';
import { requestForgotPasswordLink } from '../../helper/DataServiceProvider';
import ForgotPasswordContainer from './ForgotPasswordContainer';

export default function ForgotPasswordPage(props: { errorMessage?: string; errorBoundary?: boolean }) {
    const [forgotPasswordResult, setForgotPasswordResult] = useState<string | undefined>(undefined);
    const [forgotPasswordError, setForgotPasswordError] = useState<string | undefined>(undefined);
    const onSubmitEmail = (event: any) => {
        setForgotPasswordResult(undefined);
        setForgotPasswordError(undefined);
        event.preventDefault();
        const data: { email: string } = {
            email: event.target.email.value,
        };
        requestForgotPasswordLink(data.email)
            .then((result) => {
                setForgotPasswordResult(result);
            })
            .catch((error: Error) => {
                setForgotPasswordError(error.message);
            });
    };
    return (
        <ForgotPasswordContainer
            onSubmitEmail={onSubmitEmail}
            result={forgotPasswordResult}
            error={forgotPasswordError}
        />
    );
}
