import React, { useState } from 'react';
import { RegistrationFormProps } from '../../../index';
import FFlogo from '../../assets/images/factionfinancialwhite.png';
import terms from '../../assets/images/Faction_Financial_Terms_and_Conditions.pdf';
import loginpic1 from '../../assets/images/loginpic1.jpg';
import loginpic2 from '../../assets/images/loginpic2.jpg';
import loginpic3 from '../../assets/images/loginpic3.jpg';
import loginpic4 from '../../assets/images/loginpic4.jpg';
import loginpic5 from '../../assets/images/loginpic5.jpeg';
import gorilla from '../../assets/images/gorilla.jpg';

export default function RegisterComponent(props: RegistrationFormProps) {
    const [firstName, setFirstName] = useState<string>(props.initialFirstName);
    const [lastName, setLastName] = useState<string>(props.initialLastName);
    const [email, setEmail] = useState<string>(props.initialEmail);

    return (
        <div className="w-full flex flex-wrap bg-black">
            <div className="w-full md:w-1/2 flex flex-col">
                <div className="flex justify-center px-32 pt-16 xl:w-3/4 overflow-hidden">
                    <a href="/">
                        <img alt="Faction Financial Logo" className="w-full h-1/2" src={FFlogo}></img>
                    </a>
                </div>
                <div className="flex flex-col md:justify-start xl:mt-8  md:px-8 md:px-12 lg:px-24 ">
                    <p className="text-center text-2xl text-white">Welcome aboard!</p>
                    <form className="flex flex-col pt-3 md:pt-8" id="registerForm" onSubmit={props.onSubmit}>
                        <div className="flex flex-col pt-4">
                            <input
                                required={true}
                                type="text"
                                id="firstname"
                                placeholder="First Name"
                                value={firstName}
                                name="firstName"
                                className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-300 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col pt-4">
                            <input
                                required={true}
                                type="text"
                                id="lastname"
                                placeholder="Last Name"
                                value={lastName}
                                name="lastName"
                                className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-300 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col pt-4">
                            <label className="text-white">Birthday</label>
                            <input
                                required={true}
                                type="date"
                                id="birthday"
                                placeholder="Full"
                                name="birthday"
                                className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-300 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>

                        <div className="flex flex-col pt-4">
                            <label className="text-white">Country</label>
                            <select
                                required={true}
                                id="Country"
                                name="country"
                                placeholder="Please Select"
                                className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-300 mt-1 leading-tight focus:outline-none focus:shadow-outline focus:"
                            >
                                <option value="US">USA</option>
                                <option value="CA">Canada</option>
                            </select>
                        </div>
                        <div className="flex flex-col pt-4">
                            <input
                                required={true}
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                placeholder="your@email.com"
                                className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-300 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col pt-4">
                            <input
                                required={true}
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Password"
                                className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-300 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        {props.registrationFail && (
                            <div
                                className="mt-10 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                role="alert"
                            >
                                <span className="block sm:inline">{props.registrationFailMessage}</span>
                                <span className="absolute top-0 bottom-0 right-0 px-4 py-3" />
                            </div>
                        )}
                        <div className="row pt-4">
                            <label style={{ display: 'inline' }} className="text-white block mt-4">
                                By signing up, you agree to our{' '}
                            </label>
                            <a style={{ display: 'inline' }} className="text-white underline" href={terms}>
                                Terms and Conditions
                            </a>
                        </div>
                        <input
                            type="submit"
                            value="Register"
                            className="bg-gray-900 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-12 rounded text-center"
                        />
                    </form>
                    <div className="text-center pt-12 pb-12 text-white">
                        <p>
                            <a href="/login" className="underline font-semibold text-white">
                                Already have an account? Login here.
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div className="w-1/2 shadow-2xl">
                <img alt="company logo" className="object-cover w-full h-screen hidden md:block" src={getPic()} />
            </div>
        </div>
    );
}

function getPic() {
    const myImg = [loginpic1, loginpic2, loginpic3, loginpic4, loginpic5];
    const myGor = Math.floor(Math.random() * 100);
    if (myGor === 0) {
        return gorilla;
    } else {
        return myImg[Math.floor(Math.random() * myImg.length)];
    }
}
