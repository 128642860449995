import ParentSize from '@vx/responsive/lib/components/ParentSize';
import { motion } from 'framer-motion';
import React from 'react';
import Moment from 'react-moment';
import { Prediction, PredictionCardState } from '../../../../index';
import { getCompanyLogoURL, getHistoricalStockPrices } from '../../../helper/DataServiceProvider';
import Logo from '../../Logo';
import PredictionCardGraph from './PredictionCardGraph';
export default class PredictionCard extends React.Component<Prediction, PredictionCardState> {
    constructor(props: Prediction) {
        super(props);
        this.state = { companyLogoURL: undefined, actualPrice: undefined, finalOpacity: 1 };
    }

    async updateTickerLogo() {
        // reset logo url
        this.setState({ companyLogoURL: undefined });
        let logoURLs: any;
        try {
            logoURLs = await getCompanyLogoURL(this.props.ticker);
        } catch (error) {
            if (!error.toString().toLowerCase().includes('request aborted')) {
                this.setState(() => {
                    throw error;
                });
            }
        }
        if (logoURLs !== undefined && logoURLs.length > 0 && logoURLs.toString().trim() !== '') {
            this.setState({ companyLogoURL: logoURLs[0]._source.logo });
        } else {
            this.setState({ companyLogoURL: undefined });
        }
    }
    async updateActualPrice() {
        let historicalPrices: any;
        this.setState({ actualPrice: undefined });
        try {
            historicalPrices = await getHistoricalStockPrices(
                this.props.ticker,
                this.props.targetDate,
                this.props.targetDate
            );
        } catch (error) {
            if (!error.toString().toLowerCase().includes('request aborted')) {
                this.setState(() => {
                    throw error;
                });
            }
        }

        if (historicalPrices !== undefined && historicalPrices.length > 0 && historicalPrices[0].Close.Valid) {
            this.setState({ actualPrice: historicalPrices[0].Close.Float64 });
        }
    }
    async componentDidMount() {
        await this.updateActualPrice();
        await this.updateTickerLogo();
    }
    async componentDidUpdate(prevProps: Prediction) {
        if (
            prevProps.ticker !== this.props.ticker ||
            prevProps.exchange !== this.props.exchange ||
            prevProps.name !== this.props.name ||
            prevProps.targetDate !== this.props.targetDate ||
            prevProps.targetPrice !== this.props.targetPrice
        ) {
            this.setState({ finalOpacity: 0 });
            await this.updateTickerLogo();
            await this.updateActualPrice();
            this.setState({ finalOpacity: 1 });
        }
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        let borderColor = 'border border-gray-900 shadow-lg shadow-gray-500/50 hover:border-gray-600';
        if (this.props.correctDirection.Valid) {
            if (this.props.correctDirection.Bool === true) {
                borderColor = 'border border-green-900 shadow-lg shadow-green-500/50 hover:border-green-600';
            } else {
                borderColor = 'border border-red-900 shadow-lg shadow-red-500/50 hover:border-red-600';
            }
        }
        const predictionClassName = 'absolute w-full  h-full z-10 ' + borderColor;
        const cardContainer = (
            <motion.div
                className="h-64 w-full rounded shadow-lg relative my-6"
                id="Prediction Card"
                animate={{
                    opacity: [0, this.state.finalOpacity],
                    transition: { duration: 0.15 },
                }}
            >
                <div className={predictionClassName}>
                    <div className="mx-6 my-6 justify-between flex" id="Prediction Info">
                        <div className="flex justify-start m-2">
                            <div className="font-bold text-xl mb-2 text-white">{this.props.ticker.toUpperCase()}</div>
                        </div>
                        {this.props.detailed && (
                            <div className="px-4 flex h-10 justify-center text-lg text-base text-white overflow-clip leading-none">
                                {this.props.name}
                            </div>
                        )}
                        {this.state.companyLogoURL && <Logo url={this.state.companyLogoURL}></Logo>}
                    </div>
                    <div className="flex flex-row justify-center">
                        {this.props.accuracy.Valid && (
                            <div className="text-gray-600 text-base px-4 text-sm">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    data-aria-label="Accuracy"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-target inline"
                                >
                                    <circle cx="12" cy="12" r="10" />
                                    <circle cx="12" cy="12" r="6" />
                                    <circle cx="12" cy="12" r="2" />
                                </svg>
                                {this.props.accuracy.Float64}
                            </div>
                        )}{' '}
                        {this.props.percentageChange.Valid && (
                            <div className="text-gray-600 text-base  px-4 text-sm">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-percent inline"
                                >
                                    <line x1="19" y1="5" x2="5" y2="19" />
                                    <circle cx="6.5" cy="6.5" r="2.5" />
                                    <circle cx="17.5" cy="17.5" r="2.5" />
                                </svg>
                                {this.props.percentageChange.Float64}
                            </div>
                        )}{' '}
                    </div>
                    <div className="">
                        <div className="text-gray-300 text-base flex flex-row justify-center text-lg">
                            <div className="font-black inline">
                                ${this.props.priceAtCreation} to ${this.props.targetPrice}
                                {this.state.actualPrice && (
                                    <div className="inline"> (Actual: ${this.state.actualPrice} )</div>
                                )}
                            </div>
                            &nbsp;
                            <Moment fromNow={true}>{this.props.targetDate}</Moment>
                        </div>
                    </div>
                    {/* <div className="opacity-50 justify-start absolute ml-5 mt-4">
                        <img alt="exchange logo" src={getExchangeLogo(this.props.exchange)}></img>
                    </div> */}
                    {this.props.priceAtCreation && (
                        <div className="text-gray-600 text-base flex flex-row justify-center text-sm">
                            Entered on&nbsp;<Moment format="LLL">{this.props.timeAtCreation}</Moment>
                        </div>
                    )}
                    {this.props.detailed && (
                        <div className="p-1 px-4 flex h-10 justify-center text-lg text-base overflow-hidden leading-none">
                            {this.props.comments}
                        </div>
                    )}
                </div>
                <div className="w-full absolute top-0 bottom-0 z-10 h-16 mt-48" id="Prediction Graph Container">
                    <ParentSize>
                        {({ width, height }) => (
                            <PredictionCardGraph
                                ticker={this.props.ticker}
                                width={width}
                                height={height}
                                minDate={this.props.timeAtCreation}
                                maxDate={this.props.targetDate}
                                targetPrice={this.props.targetPrice}
                                priceAtCreation={this.props.priceAtCreation}
                            />
                        )}
                    </ParentSize>
                </div>
            </motion.div>
        );
        if (this.props.interactable) {
            return <a href={'/prediction/' + this.props.id}>{cardContainer}</a>;
        } else {
            return cardContainer;
        }
    }
}
