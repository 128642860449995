// A wrapper for <Register> that redirects to the app if you are authenticated

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useGlobalState } from '../helper/Context';

export function SignUpRoute({ children, ...rest }: any) {
    const [state] = useGlobalState();
    if (state.isAuthenticated === false) {
        return <Route exact={true} {...rest} render={({ location }) => children} />;
    } else {
        return (
            <Redirect
                to={{
                    pathname: '/dashboard',
                }}
            />
        );
    }
}
