import React from 'react';
import { ErrorBoundaryState } from '../..';
import { deleteCredentials } from '../helper/CredentialManager';
import LoginFormContainer from './LoginForm/LoginFormContainer';

export default class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false, errorMessage: undefined };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error };
    }
    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        console.log(error + ':' + errorInfo);
    }
    render() {
        let displayErrorMessage;
        if (this.state.hasError) {
            let errorMessage = this.state.errorMessage.message.toString();
            deleteCredentials();
            if (errorMessage === '' || errorMessage === undefined || errorMessage === 'Error') {
                displayErrorMessage = undefined;
            } else if (errorMessage.toLowerCase().includes('403')) {
                displayErrorMessage = 'Credentials expired. Please relogin';
            } else if (!this.state.errorMessage.toString().toLowerCase().includes('credentials expired')) {
                // if its not an expired credential, print out a generic error
                displayErrorMessage =
                    'Something has gone wrong with our app. We cleared your credentials just to be safe. Please relogin.';
            }
            console.log('errorboundary has been used ' + errorMessage);
            return <LoginFormContainer errorMessage={displayErrorMessage} errorBoundary={true} />;
        }
        return this.props.children;
    }
}
