import { motion } from 'framer-motion';
import React from 'react';
import Moment from 'react-moment';
import { Prediction, PredictionCardState } from '../../../../index';
import { getCompanyLogoURL, getHistoricalStockPrices } from '../../../helper/DataServiceProvider';
export default class HistoryTable extends React.Component<Prediction, PredictionCardState> {
    constructor(props: Prediction) {
        super(props);
        this.state = { companyLogoURL: undefined, actualPrice: undefined, finalOpacity: 1 };
    }

    async updateTickerLogo() {
        // reset logo url
        this.setState({ companyLogoURL: undefined });
        let logoURLs: any;
        try {
            logoURLs = await getCompanyLogoURL(this.props.ticker);
        } catch (error) {
            if (!error.toString().toLowerCase().includes('request aborted')) {
                this.setState(() => {
                    throw error;
                });
            }
        }
        if (logoURLs !== undefined && logoURLs.length > 0 && logoURLs.toString().trim() !== '') {
            this.setState({ companyLogoURL: logoURLs[0]._source.logo });
        } else {
            this.setState({ companyLogoURL: undefined });
        }
    }
    async updateActualPrice() {
        let historicalPrices: any;
        this.setState({ actualPrice: undefined });
        try {
            historicalPrices = await getHistoricalStockPrices(
                this.props.ticker,
                this.props.targetDate,
                this.props.targetDate
            );
        } catch (error) {
            if (!error.toString().toLowerCase().includes('request aborted')) {
                this.setState(() => {
                    throw error;
                });
            }
        }

        if (historicalPrices !== undefined && historicalPrices.length > 0 && historicalPrices[0].Close.Valid) {
            this.setState({ actualPrice: historicalPrices[0].Close.Float64 });
        }
    }
    async componentDidMount() {
        await this.updateActualPrice();
        await this.updateTickerLogo();
    }
    async componentDidUpdate(prevProps: Prediction) {
        if (
            prevProps.ticker !== this.props.ticker ||
            prevProps.exchange !== this.props.exchange ||
            prevProps.name !== this.props.name ||
            prevProps.targetDate !== this.props.targetDate ||
            prevProps.targetPrice !== this.props.targetPrice
        ) {
            this.setState({ finalOpacity: 0 });
            await this.updateTickerLogo();
            await this.updateActualPrice();
            this.setState({ finalOpacity: 1 });
        }
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    showPredictionTrend() {
        if (this.props.correctDirection.Valid) {
            if (this.props.correctDirection.Bool === true) {
                return 'text-green-500 px-5 py-3  bg-black text-center text-sm uppercase font-normal width: 20% border-b border-grey-200';
            } else {
                return 'text-red-500 px-5 py-3  bg-black text-center text-sm uppercase font-normal width: 20% border-b border-grey-200';
            }
        } else {
            return 'text-white px-5 py-3  bg-black text-center text-sm uppercase font-normal width: 20% border-b border-grey-200';
        }
    }

    render() {
        const borderColour = this.showPredictionTrend();
        const cardContainer = (
            <motion.div
                className="h-full w-full rounded shadow-lg relative my-full"
                id="Prediction Card"
                animate={{
                    opacity: [0, this.state.finalOpacity],
                    transition: { duration: 0.15 },
                }}
            >
                <table className="min-w-full w-auto">
                    <thead>
                        <tr data-tip data-for="PD Card">
                            <th
                                scope="col"
                                className={borderColour}
                                style={{ width: 100 / 6 + '%', textAlign: 'center' }}
                            >
                                {this.props.ticker}
                            </th>
                            <th
                                scope="col"
                                className={borderColour}
                                style={{ width: 100 / 6 + '%', textAlign: 'center' }}
                            >
                                {this.props.priceAtCreation}
                            </th>
                            <th
                                scope="col"
                                className={borderColour}
                                style={{ width: 100 / 6 + '%', textAlign: 'center' }}
                            >
                                {this.props.targetPrice}
                            </th>
                            <th
                                scope="col"
                                className={borderColour}
                                style={{ width: 100 / 6 + '%', textAlign: 'center' }}
                            >
                                <Moment format="LLL">{this.props.timeAtCreation}</Moment>
                            </th>
                            <th
                                scope="col"
                                className={borderColour}
                                style={{ width: 100 / 6 + '%', textAlign: 'center' }}
                            >
                                <Moment format="LLL">{this.props.targetDate}</Moment>
                            </th>
                        </tr>
                    </thead>
                </table>
            </motion.div>
        );

        if (this.props.interactable) {
            return <a href={'/prediction/' + this.props.id}>{cardContainer}</a>;
        } else {
            return cardContainer;
        }
    }
}
