import { animate, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { Rank } from '../..';
import { getRankInformation } from '../helper/AssetProvider';
import { getUserRating } from '../helper/DataServiceProvider';
import Loading from './Loading';

export default function RankDisplay() {
    const currentScoreRef = useRef<any>();
    const [rating, setRating] = useState<number | undefined>(undefined);
    const [rankProperties, setRankProperties] = useState<Rank | undefined>(undefined);

    useEffect(() => {
        let isMounted = true; // not
        getUserRating().then((userRating) => {
            if (isMounted && userRating !== undefined) {
                setRating(userRating);
                setRankProperties(getRankInformation(userRating));
            }
        });
        return () => {
            isMounted = false;
        }; // use effect cleanup to set flag false, if unmounted
    }, []);
    useEffect(() => {
        const node = currentScoreRef.current;
        if (node && node !== undefined && rating !== undefined) {
            const controls = animate(0, rating, {
                duration: 2,
                onUpdate(value) {
                    node.textContent = value.toFixed(0) + ' of ' + (rankProperties as Rank).levelCeiling;
                },
            });
            return () => controls.stop();
        }
    }, [rating, currentScoreRef, rankProperties]);

    if (rating === undefined || rankProperties === undefined) {
        // Render Loading State
        return <Loading />;
    }
    const levelCeiling = (rankProperties as Rank).levelCeiling;
    const nextRank = (rankProperties as Rank).nextRank;
    if ((rankProperties as Rank).level === 'Diamond') {
        // ranked user but diamond max level
        return <div className="text-gray-400">You are among our highest ranked users!</div>;
    } else {
        // you are a non-diamond ranked user
        return (
            <motion.div>
                {levelCeiling &&
                    nextRank &&
                    (rankProperties as Rank).colorStop1 &&
                    (rankProperties as Rank).colorStop2 && (
                        <div className="flex flex-col justify-center">
                            <div className=" text-white content-center m-auto mb-4">
                                {(rankProperties as Rank).level} Rated
                            </div>
                            <svg className="progress-icon" viewBox="0 0 50 50">
                                <g>
                                    {' '}
                                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop
                                            offset="0%"
                                            style={{ stopColor: (rankProperties as Rank).colorStop1, stopOpacity: 1 }}
                                        />
                                        <stop
                                            offset="100%"
                                            style={{ stopColor: (rankProperties as Rank).colorStop2, stopOpacity: 1 }}
                                        />
                                    </linearGradient>
                                    <motion.path
                                        d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                                        fill="none"
                                        stroke="url(#grad1)"
                                        strokeWidth="5"
                                        initial={{ pathLength: 0 }}
                                        animate={{ pathLength: rating / levelCeiling }}
                                        exit={{ pathLength: 0 }}
                                        transition={{ duration: 2 }}
                                        style={{
                                            rotate: 90,
                                            translateX: 5,
                                            translateY: 5,
                                            scaleX: -1, // Reverse direction of line animation
                                        }}
                                    />
                                    <text
                                        textAnchor="middle"
                                        x="25"
                                        y="25"
                                        ref={currentScoreRef}
                                        fontSize="4"
                                        fill="white"
                                    ></text>
                                </g>
                            </svg>
                            <div className="text-gray-400 content-center  m-auto mt-4">
                                {levelCeiling - rating} until {nextRank}
                            </div>
                        </div>
                    )}
            </motion.div>
        );
    }
}
