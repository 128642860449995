import React, { useState } from 'react';
import { LoginCredentials } from '../../../index';
import { useGlobalState } from '../../helper/Context';
import { deleteCredentials, setCredentials } from '../../helper/CredentialManager';
import { tryLogin } from '../../helper/DataServiceProvider';
import LoginForm from './LoginForm';

export default function LoginFormContainer(props: { errorMessage?: string; errorBoundary?: boolean }) {
    const [state, dispatch] = useGlobalState();
    let defaultLoginFail = false;
    if (state.message !== undefined || props.errorMessage) {
        defaultLoginFail = true;
    }
    const [loginFail, loginFailed] = useState(defaultLoginFail);
    const [loginFailMessage, loginFailedMessage] = useState(state.message || props.errorMessage);
    const onSubmitLogin = (event: any) => {
        loginFailed(false);
        loginFailedMessage(undefined);
        event.preventDefault();
        const formData: LoginCredentials = {
            email: event.target.email.value,
            password: event.target.password.value,
        };
        tryLogin(formData)
            .then((account: any) => {
                deleteCredentials();
                dispatch({ isAuthenticated: false, userToken: undefined });
                console.log('Login Succeeded');
                setCredentials({ token: account.token });
                dispatch({
                    isAuthenticated: true,
                    userToken: account.token,
                });
                if (props.errorBoundary) {
                    // this is being called by our error boundary, we need to refresh the page to reload the app
                    window.location.reload();
                }
            })
            .catch((error: Error) => {
                if (error.message.includes('status code 403')) {
                    const errorMessage = 'Credentials expired. Please relogin';
                    deleteCredentials();
                    dispatch({
                        isAuthenticated: false,
                        userToken: undefined,
                        message: errorMessage,
                    });
                    loginFailed(true);
                    loginFailedMessage(errorMessage);
                } else {
                    console.log('Login failed');
                    console.log(error.toString());
                    loginFailed(true);
                    loginFailedMessage(error.message.toString());
                }
            });
    };
    return <LoginForm onSubmitLogin={onSubmitLogin} loginFail={loginFail} loginFailMessage={loginFailMessage} />;
}
