import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { getTopTenUsers } from '../../helper/DataServiceProvider';
import { TopTenUsers } from '../../..';
export default function TopTenUsersContainer() {
    const [topTenUsers, setTopTenUsers] = useState<TopTenUsers[]>([]);
    useEffect(() => {
        const getTopTenUsersDetail = async function () {
            try {
                const topTenUsers = await getTopTenUsers();
                if (topTenUsers !== undefined) {
                    setTopTenUsers(topTenUsers);
                }
            } catch (error) {
                console.log('cannot find top ten users');
            }
        };
        getTopTenUsersDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="container mx-auto px-4 sm:px-8 max-w-full border-b border-gray-200">
            <div className="py-8">
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <p className="text-4xl tracking-tight leading-10 font-extrabold text-gray-300 sm:leading-none">
                            Our Best Analysts
                        </p>
                        <div className="flex min-w-full w-auto">
                            <div
                                className="flex-1 px-5 py-3  bg-black  border-b border-gray-200 text-white text-center text-lg uppercase font-normal"
                                style={{ width: 100 / 6 + '%', textAlign: 'center' }}
                            >
                                Rank
                            </div>
                            <div
                                className="flex-1 px-5 py-3  bg-black  border-b border-gray-200 text-white text-center text-lg uppercase font-normal"
                                style={{ width: 100 / 6 + '%', textAlign: 'center' }}
                            >
                                Analyst
                            </div>
                        </div>
                        {topTenUsers &&
                            topTenUsers.length > 0 &&
                            topTenUsers.map(function (prediction, index) {
                                return (
                                    <div>
                                        <motion.div
                                            animate={{
                                                opacity: [0, 1],
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: 'easeIn',
                                                times: [0, 0.5],
                                            }}
                                            className="h-full w-full rounded shadow-lg relative my-full"
                                        ></motion.div>
                                        <div className="flex min-w-full w-auto" data-tip data-for="PD Card">
                                            <div
                                                className="flex-1 text-white px-5 py-3  bg-black text-center text-sm uppercase font-normal width: 20% border-b border-grey-200"
                                                style={{ width: 100 / 6 + '%', textAlign: 'center' }}
                                            >
                                                {prediction.Rank}
                                            </div>
                                            <div
                                                className="flex-1 text-white px-5 py-3  bg-black text-center text-sm uppercase font-normal width: 20% border-b border-grey-200"
                                                style={{ width: 100 / 6 + '%', textAlign: 'center' }}
                                            >
                                                {prediction.Name}
                                            </div>
                                        </div>
                                        ;
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
}
