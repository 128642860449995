import { animate, motion } from 'framer-motion';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { PredictionRequest, Ticker } from '../../../..';
import { getCurrentStockPrice } from '../../../helper/DataServiceProvider';
import { getCurrency, getMinDate, useAsyncError } from '../../../helper/util';
import AutosuggestTicker from './AutosuggestTicker';
import PredictionSubmissionDialog from './PredictionDialog';
import SingleDayPicker from './SingleDayPicker';

export default function PredictionForm(props: { updatePredictionCount: any }) {
    const currentTickerPriceRef = useRef<any>();
    const [from, setTickerPriceFrom] = useState<number>(0);
    const [to, setTickerPriceTo] = useState<number>(0);
    const [ticker, setTicker] = useState<Ticker | undefined>(undefined);
    const [comments, setComments] = useState<string | undefined>(undefined);
    const [tickerError, setTickerError] = useState<string | undefined>(undefined);
    const [priceError, setPriceError] = useState<string | undefined>(undefined);
    const [dateError, setDateError] = useState<string | undefined>(undefined);
    const [priceBorder, setPriceBorder] = useState<string>('border-grey-200');
    const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);
    const [currentTickerPrice, setCurrentTickerPrice] = useState<number | undefined>(undefined);
    const [targetPrice, setTargetPrice] = useState<number | undefined>(undefined);
    const [targetDate, setTargetDate] = useState<Date | undefined>(undefined);
    const [showDialog, setDialog] = useState<boolean>(false);
    const [predictionRequest, setPredictionRequest] = useState<PredictionRequest | undefined>(undefined);
    const throwError = useAsyncError();

    const resetForm = () => {
        setTicker(undefined);
        setTickerError(undefined);
        setPriceError(undefined);
        setDateError(undefined);
        setSubmissionError(undefined);
        setTargetPrice(undefined);
        setTargetDate(undefined);
        setCurrentTickerPrice(undefined);
        setPriceBorder('border-grey-200');
        setComments(undefined);
    };
    useEffect(() => {
        const getCurrentPrice = async function () {
            try {
                if (ticker && ticker.ticker) {
                    const currentPrice = await getCurrentStockPrice(ticker.ticker);
                    if (currentPrice !== undefined) {
                        if (currentTickerPrice !== undefined) {
                            setTickerPriceFrom(currentTickerPrice);
                        }
                        setCurrentTickerPrice(currentPrice.latestPrice);
                        setTickerPriceTo(currentPrice.latestPrice);
                    }
                }
            } catch (error) {
                console.log('Error retrieving current price');
            }
        };
        // get the latest stock price
        getCurrentPrice();
    }, [ticker, currentTickerPrice]);
    useEffect(() => {
        const node = currentTickerPriceRef.current;
        if (node && node !== undefined) {
            const controls = animate(from, to, {
                duration: 1,
                onUpdate(value) {
                    node.textContent = 'Currently: $' + value.toFixed(2);
                },
            });
            return () => controls.stop();
        }
    }, [from, to, currentTickerPrice]);
    const handleSubmit = (evt: any) => {
        evt.preventDefault();
        if (ticker === undefined || targetPrice === undefined || targetDate === undefined) {
            // check for empty submissions
            if (ticker === undefined) {
                setTickerError(' Please fill in the ticker field ');
            }
            if (targetPrice === undefined) {
                setPriceError(' Please fill in the price field ');
                setPriceBorder('border-red-500');
            }
            if (targetDate === undefined) {
                setDateError(' Please fill in the date field ');
            }
            return;
        }
        const prediction: PredictionRequest = {
            ticker: ticker.ticker,
            exchange: ticker.exchange,
            name: ticker.name,
            targetPrice: targetPrice,
            comments: comments,
            targetDate: moment(targetDate).format(),
        };
        // make the dialog box pop up after verification
        setDialog(true);
        setPredictionRequest(prediction);
    };
    const updateFormDate = (day: Date) => {
        setTargetDate(day);
    };
    return (
        <form autoComplete="off" className="w-full" onSubmit={handleSubmit}>
            <PredictionSubmissionDialog
                updatePredictionCount={props.updatePredictionCount}
                setSubmissionError={setSubmissionError}
                showDialog={showDialog}
                resetForm={resetForm}
                throwError={throwError}
                setDialog={setDialog}
                predictionRequest={predictionRequest}
                currentTickerPrice={currentTickerPrice}
            />
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <div className="font-bold text-xl mb-2 text-gray-200">Make a Prediction</div>
                    <label className="inline uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Ticker
                    </label>
                    {tickerError && (
                        <label className="inline tracking-wide text-red-700 text-xs font-bold mb-2">
                            {tickerError}
                        </label>
                    )}
                    <AutosuggestTicker setTicker={setTicker} ticker={ticker} setTickerError={setTickerError} />
                    {currentTickerPrice !== undefined && ticker !== undefined && (
                        <motion.div
                            animate={{
                                opacity: [0, 1],
                                x: [0, 0],
                                y: [0, -30],
                            }}
                        >
                            <label ref={currentTickerPriceRef} className="tracking-wide text-lg text-white"></label>
                            <label className="tracking-wide text-white text-xs mb-2">
                                &nbsp;{getCurrency(ticker.exchange)}
                            </label>
                        </motion.div>
                    )}
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold -mt-5 mb-2">
                        Target Price
                    </label>
                    {priceError && (
                        <label className="inline tracking-wide text-red-700 text-xs font-bold mb-2">{priceError}</label>
                    )}
                    <input
                        className={
                            'appearance-none block w-full bg-black text-gray-700 border ' +
                            priceBorder +
                            ' rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-800 focus:border-gray-500'
                        }
                        name="targetPrice"
                        id="TargetPrice"
                        placeholder="Enter Target Price"
                        type="number"
                        step="0.01"
                        value={targetPrice === undefined ? '' : targetPrice.toString()}
                        min={0}
                        max={10000000}
                        onChange={(e) => {
                            setTargetPrice(Number(e.target.value));
                            setPriceBorder('border-green-500');
                            setPriceError(undefined);
                        }}
                    />
                    <label className="inline uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Target Date
                    </label>
                    {dateError && (
                        <label className="inline tracking-wide text-red-700 text-xs font-bold mb-2">{dateError}</label>
                    )}
                    <div
                        className={
                            'appearance-none block w-full  leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                        }
                    >
                        <SingleDayPicker
                            targetDate={targetDate}
                            updateFormDate={updateFormDate}
                            disabledDays={[
                                { daysOfWeek: [0, 6] },
                                {
                                    before: getMinDate(),
                                },
                            ]}
                        />
                    </div>
                    <label className="inline uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Comments (Optional)
                    </label>
                    <input
                        className={
                            'appearance-none block w-full bg-black text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-800 focus:border-gray-500'
                        }
                        name="comments"
                        id="Comments"
                        placeholder="Enter any comments"
                        type="text"
                        value={comments === undefined ? '' : comments.toString()}
                        onChange={(e) => {
                            setComments(e.target.value);
                        }}
                    />
                    <button
                        className="bg-transparent w-full hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                        value="Submit"
                        type="submit"
                    >
                        Submit Prediction
                    </button>
                    <div>
                        {submissionError && (
                            <label className="block tracking-wide text-red-700 text-xs font-bold mb-2">
                                {submissionError}
                            </label>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
}
