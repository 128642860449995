import { motion } from 'framer-motion';
import React from 'react';
import jude from '../../assets/images/jude.jpg';
import tejpal from '../../assets/images/tejpal.jpg';
export default function About() {
    const elostyle = 'px-4 py-3 text-gray-300';
    return (
        <motion.div
            className="flex-col justify-center mt-16 flex-wrap text-white bg-black min-h-screen "
            animate={{
                opacity: [0, 1],
            }}
            transition={{
                duration: 3,
                ease: 'easeIn',
                times: [0, 0.2],
            }}
        >
            <section
                className=" body-font"
                id="About"
                style={{ background: `linear-gradient(to right, #0a0a70,#2b7cbe)` }}
            >
                <div className="container px-5 py-24 mx-auto flex flex-wrap">
                    <h2 className="text-3xl font-extrabold text-transparent title-font mb-2 md:w-2/5  bg-clip-text bg-gradient-to-r from-yellow-500 to-yellow-200">
                        Faction Financial is the easiest way to prove your worth as a financial analyst
                    </h2>
                    <div className="md:w-3/5"></div>
                    <div className="md:w-3/5">
                        <p className="text-base">
                            Faction Financial crowdsources its information from analysts. This is fed to our AI, which
                            evaluates, ranks and pays analysts based solely on performance.
                        </p>
                        <br></br>
                        <div className="flex md:mt-4 mt-6">
                            <a href="/register">
                                <button className="inline-flex text-white bg-blue-500 border-0 py-1 px-4 focus:outline-none hover:bg-blue-600 rounded">
                                    Sign Up
                                </button>
                            </a>
                            <a className="text-blue-500 inline-flex items-center ml-4" href="#compensation">
                                Compensation
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    className="w-4 h-4 ml-2"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className="text-gray-600 body-font"
                id="compensation"
                style={{ background: `linear-gradient(to bottom right,#000000, #404040)` }}
            >
                <div className="container px-5 py-12 mx-auto">
                    <div className="flex flex-col text-center w-full mb-20">
                        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-400">
                            Compensation Chart
                        </h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-300 pt-6">
                            Your rank is a representation of your{' '}
                            <a href="https://en.wikipedia.org/wiki/Elo_rating_system" className="text-blue-500">
                                performance relative to the crowd.
                            </a>
                        </p>
                        <br></br>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-300">
                            Higher ranks gain more perks and higher earnings. See the chart below to see our current,
                            (and ever increase), compensation ceilings.
                        </p>
                    </div>

                    <div className="lg:w-1/2 w-full mx-auto overflow-auto">
                        <table className="table-auto w-full text-center whitespace-no-wrap">
                            <thead>
                                <tr>
                                    <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-blue-500 rounded-tl rounded-bl">
                                        Rank
                                    </th>
                                    <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-blue-500">
                                        ELO Rating
                                    </th>
                                    <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-blue-500">
                                        Monthly Compensation Ceiling*
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="px-4 py-3 font-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-yellow-800 to-yellow-500">
                                        Bronze
                                    </td>
                                    <td className={elostyle}>1000-1999</td>
                                    <td className="px-4 py-3 text-lg text-gray-300">$0</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-3 font-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-gray-50 to-blue-200">
                                        Silver
                                    </td>
                                    <td className={elostyle}>2000-2999</td>
                                    <td className="px-4 py-3 text-lg text-gray-300 font-semibold">$0</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-3 font-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-yellow-200 to-yellow-600">
                                        Gold
                                    </td>
                                    <td className={elostyle}>3000-3999</td>
                                    <td className="px-4 py-3 text-lg text-gray-300 font-bold">$1500</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-3 font-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-blue-300">
                                        Platinum
                                    </td>
                                    <td className={elostyle}>4000-4999</td>
                                    <td className="px-4 py-3 text-lg text-gray-300 font-extrabold">$6500</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-3 font-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-gray-500 to-blue-400">
                                        Diamond
                                    </td>
                                    <td className={elostyle}>5000+</td>
                                    <td className="px-4 py-3 text-lg text-gray-300 font-black">$21500</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex pl-4 mt-4 lg:w-2/3 w-full mx-auto">
                        <a className="text-blue-500 inline-flex items-center md:mb-2 lg:mb-0" href="#benefits">
                            Benefits
                            <svg
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                className="w-4 h-4 ml-2"
                                viewBox="0 0 24 24"
                            >
                                <path d="M5 12h14M12 5l7 7-7 7" />
                            </svg>
                        </a>
                        <p className="flex ml-auto text-white  border-0 py-2 px-6 focus:outline-none  rounded">
                            *Subject to change as AUM grows
                        </p>
                    </div>
                </div>
            </section>
            <section
                className="text-gray-600 body-font"
                id="benefits"
                style={{ background: `linear-gradient(to bottom left, #404040,#000000)` }}
            >
                <div className="container px-5 py-24 mx-auto flex flex-wrap">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-4 lg:w-1/2 md:w-full">
                            <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
                                <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 flex-shrink-0">
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        className="w-8 h-8"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                    </svg>
                                </div>
                                <div className="flex-grow">
                                    <h2 className="text-gray-300 text-lg title-font font-medium mb-3">Top Analysts</h2>
                                    <p className="leading-relaxed text-gray-400 text-base">
                                        We interview our best performing analysts for full-time positions.
                                    </p>
                                    {/* <a className="mt-3 text-blue-500 inline-flex items-center">
                                        Learn More
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            className="w-4 h-4 ml-2"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/2 md:w-full">
                            <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
                                <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 flex-shrink-0">
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        className="w-10 h-10"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                </div>
                                <div className="flex-grow">
                                    <h2 className="text-gray-300 text-lg title-font font-medium mb-3">
                                        Access to the Faction Fund Predictions
                                    </h2>
                                    <p className="leading-relaxed text-gray-400 text-base">
                                        All analysts gain access to our AI filtered stock picks.
                                    </p>
                                    <a className="mt-3 text-blue-500 inline-flex items-center" href="#team">
                                        Our Team
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            className="w-4 h-4 ml-2"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-gray-600 body-font" id="team">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-col text-center w-full mb-20">
                        <h1 className="text-2xl font-medium title-font mb-4 text-gray-500 tracking-widest">
                            OUR CO-FOUNDERS
                        </h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                            Feel free to reach out to us at anytime. Send us an email if you're looking for a career
                            opportunity.
                        </p>
                    </div>
                    <div className="flex flex-wrap -m-4">
                        <div className="p-4 lg:w-1/2">
                            <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                                <img
                                    alt="team"
                                    className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                                    src={jude}
                                ></img>
                                <div className="flex-grow sm:pl-8">
                                    <h2 className="title-font font-medium text-lg text-gray-500">Jude Tillekeratne</h2>
                                    <h3 className="text-gray-400 mb-3">Co-founder</h3>
                                    <p className="mb-4"></p>
                                    <span className="inline-flex">
                                        <a className="ml-2 text-gray-500" href="https://www.linkedin.com/in/judetill/">
                                            <svg
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                className="w-5 h-5"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M12 9.55C12.917 8.613 14.111 8 15.5 8a5.5 5.5 0 0 1 5.5 5.5V21h-2v-7.5a3.5 3.5 0 0 0-7 0V21h-2V8.5h2v1.05zM5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-1 2h2V21H4V8.5z" />
                                            </svg>
                                        </a>
                                        <a className="ml-2 text-gray-500" href="mailto:jude@factionfinancial.com">
                                            <svg
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                className="w-5 h-5"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z" />{' '}
                                            </svg>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/2">
                            <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                                <img
                                    alt="team"
                                    className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                                    src={tejpal}
                                ></img>
                                <div className="flex-grow sm:pl-8">
                                    <h2 className="title-font font-medium text-lg text-gray-500">Tejpal Sahota</h2>
                                    <h3 className="text-gray-400 mb-3">Co-founder</h3>
                                    <p className="mb-4"></p>
                                    <span className="inline-flex">
                                        <a className="ml-2 text-gray-500" href="https://www.linkedin.com/in/tsahota/">
                                            <svg
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                className="w-5 h-5"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M12 9.55C12.917 8.613 14.111 8 15.5 8a5.5 5.5 0 0 1 5.5 5.5V21h-2v-7.5a3.5 3.5 0 0 0-7 0V21h-2V8.5h2v1.05zM5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-1 2h2V21H4V8.5z" />{' '}
                                            </svg>
                                        </a>
                                        <a className="ml-2 text-gray-500" href="mailto:tej@factionfinancial.com">
                                            <svg
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                className="w-5 h-5"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z" />{' '}
                                            </svg>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    );
}
