import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { AutoSuggestTickerProps, Ticker } from '../../../..';
import { getAutosuggestions } from '../../../helper/DataServiceProvider';
import Logo from '../../Logo';

export default function AutosuggestTicker(props: AutoSuggestTickerProps) {
    const suggestionMenu = useRef<any>(undefined);
    const [currentTickerInput, setCurrentTickerInput] = useState<string>('');
    const [chosenTicker, setChosenTicker] = useState<Ticker | undefined>(undefined);
    const [inputValid, setInputValid] = useState<boolean | undefined>(undefined);
    const [suggestions, setSuggestions] = useState<Array<Ticker>>([]);

    const getSuggestions = function (event: any) {
        setInputValid(false);
        setCurrentTickerInput(event.target.value);
        // clear current values
        setChosenTicker(undefined);
        getAutosuggestions(event.target.value)
            .then((suggestions) => {
                if (suggestions !== undefined) {
                    setSuggestions(suggestions);
                }
            })
            .catch((e) => {});
    };
    const showSuggestions = function (event: any) {
        document.addEventListener('click', hideSuggestions);
        getAutosuggestions(event.target.value)
            .then((suggestions) => {
                if (suggestions !== undefined) {
                    setSuggestions(suggestions);
                }
            })
            .catch((e) => {});
    };
    const hideSuggestions = function (event: any) {
        if (
            suggestionMenu &&
            suggestionMenu.current &&
            !suggestionMenu.current.contains(event.target) &&
            chosenTicker === undefined
        ) {
            console.log('outside autosuggest');
            // reset ticker field since its invalid
            props.setTicker(undefined);
            setCurrentTickerInput('');
            setInputValid(false);
        } else if (suggestionMenu && suggestionMenu.current && suggestionMenu.current.contains(event.target)) {
            console.log('inside autosuggest');
            setInputValid(true);
        } else {
            props.setTicker(undefined);
            setCurrentTickerInput('');
        }
        setSuggestions([]);
        document.removeEventListener('click', hideSuggestions);
    };
    const chooseSuggestion = function (event: any) {
        const selectedSuggestion: Ticker = {
            ticker: event.currentTarget.dataset.ticker,
            exchange: event.currentTarget.dataset.exchange,
            name: event.currentTarget.dataset.name,
        };
        // save selected ticker
        setChosenTicker(selectedSuggestion);
        setCurrentTickerInput('(' + selectedSuggestion.ticker + ')  ' + selectedSuggestion.name);
        props.setTicker(selectedSuggestion);
        props.setTickerError(undefined);
        // input is now valid
        setInputValid(true);
    };
    useEffect(() => {
        if (props.ticker === undefined) {
            setCurrentTickerInput('');
            setChosenTicker(undefined);
            setInputValid(undefined);
            setSuggestions([]);
        }
    }, [props.ticker]);

    const renderTickerField = function () {
        switch (inputValid) {
            case true:
                return 'flex appearance-none block w-full bg-black text-gray-700 border border-green-500 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-800';
            case false:
                return 'flex appearance-none block w-full bg-black text-gray-700 border border-red-500 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-800';
            default:
                return 'flex appearance-none block w-full bg-black text-gray-700 border py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-800';
        }
    };

    return (
        <div className="AutoSuggestTicker">
            <div className="flex">
                <input
                    data-lpignore="true"
                    className={renderTickerField()}
                    name="ticker"
                    type="text"
                    id="tickerInput"
                    placeholder="Ticker / Company Name"
                    value={currentTickerInput}
                    onChange={getSuggestions}
                    onClick={showSuggestions}
                />
            </div>
            <div
                className="absolute bg-black z-40"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
            >
                <div className="" id="suggestions" ref={suggestionMenu}>
                    {suggestions.map((suggestion: any, index: number) => (
                        <motion.div
                            id={'suggestion' + index}
                            initial={{ x: -10 }}
                            animate={{
                                opacity: [0, 1],
                                x: [-10, 0],
                            }}
                            transition={{
                                duration: 1,
                                ease: 'easeIn',
                                times: [0, 0.5],
                            }}
                            className="flex  m-3 border border-gray-800 bg-black w-64"
                            key={suggestion._id}
                        >
                            <button
                                id={suggestion._id}
                                className="w-full px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100
                                hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 "
                                role="menuitem"
                                onClick={chooseSuggestion}
                                data-name={suggestion._source.company}
                                data-ticker={suggestion._source.ticker}
                                data-exchange={suggestion._source.exchange}
                            >
                                {suggestion._source.logo !== undefined &&
                                    suggestion._source.logo.toString().trim() !== '' && (
                                        <Logo className="h-6 sm:h-8 md:h-12" url={suggestion._source.logo} />
                                    )}

                                <div className="flex justify-between">
                                    <div className="text-gray-700 text-lg">{suggestion._source.ticker}</div>
                                    <div className="">{suggestion._source.Exchange} </div>
                                </div>
                                <div>{suggestion._source.company}</div>
                            </button>
                        </motion.div>
                    ))}
                </div>
            </div>
            )
        </div>
    );
}
