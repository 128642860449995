import React, { useState } from 'react';
import { RegistrationDetails } from '../../../index';
import { tryRegister } from '../../helper/DataServiceProvider';
import RegisterForm from './RegisterForm';
import RegisterSuccessContainer from './RegisterSuccessContainer';

export default function RegisterFormContainer(props: any) {
    let initialEmail = '';
    let initialFirstName = '';
    let initialLastName = '';
    if (props.location && props.location.state) {
        if (props.location.state.email) initialEmail = props.location.state.email;
        if (props.location.state.firstName) initialFirstName = props.location.state.firstName;
        if (props.location.state.lastName) initialLastName = props.location.state.lastName;
    }
    const [successfulState, setSuccessfulRegister] = useState(false);
    const [registrationFail, registrationFailed] = useState(false);
    const [registrationFailMessage, registrationFailedMessage] = useState<string | undefined>(undefined);
    const onSubmit = (event: any) => {
        registrationFailed(false);
        registrationFailedMessage(undefined);
        event.preventDefault();
        const formData: RegistrationDetails = {
            email: event.target.email.value,
            password: event.target.password.value,
            countrycode: event.target.country.value,
            firstName: event.target.firstName.value,
            lastName: event.target.lastName.value,
            role: 'analyst',
            birthday: event.target.birthday.value,
        };
        tryRegister(formData)
            .then((result: any) => {
                console.log(result);
                setSuccessfulRegister(true);
            })
            .catch((error: Error) => {
                console.log('Registration failed');
                console.log(error.message.toString());
                registrationFailed(true);
                registrationFailedMessage(error.message.toString());
            });
    };
    if (successfulState) {
        return <RegisterSuccessContainer />;
    } else {
        return (
            <RegisterForm
                initialEmail={initialEmail}
                initialFirstName={initialFirstName}
                initialLastName={initialLastName}
                onSubmit={onSubmit}
                registrationFail={registrationFail}
                registrationFailMessage={registrationFailMessage}
            />
        );
    }
}
