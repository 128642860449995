import React from 'react';
import { LogoProps } from '../..';

export default function Logo(props: LogoProps) {
    if (props.url === undefined) {
        return <React.Fragment />;
    }
    const url = new URL(props.url);
    if (url.pathname === '' || url.pathname.endsWith('placeholder.png') || props.url.trim() === '') {
        return <React.Fragment />;
    }
    return (
        <img
            className={props.className || 'ml-auto h-12 justify-end rounded-full'}
            src={props.url}
            alt={'Company Logo'}
        />
    );
}
