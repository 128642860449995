import { motion } from 'framer-motion';
import React, { Component } from 'react';
export default class Loading extends Component<{}, {}> {
    render() {
        const icon = {
            hidden: {
                opacity: 0,
                pathLength: 0,
                fill: 'rgba(255, 255, 255, 0)',
            },
            visible: {
                opacity: 1,
                pathLength: 1,
                fill: 'rgba(255, 255, 255, 1)',
            },
        };
        return (
            <div className="w-full flex flex-wrap bg-black">
                <div className="flex flex-1  stroke-2 text-white">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 194 253"
                        className="item stroke-2 stroke-current"
                    >
                        <motion.path
                            d="M47.29,213.87V40.81h96.14v10.1H57.39v71.39h71.63v10.09H57.39
                        v81.48H47.29z"
                            variants={icon}
                            initial="hidden"
                            animate="visible"
                            transition={{
                                default: { duration: 1.3, ease: 'easeInOut' },
                                fill: { duration: 1.3, ease: [1, 0, 0.8, 1] },
                            }}
                        />
                        <g>
                            <defs>
                                <motion.path
                                    id="SVGID_1_"
                                    d="M 184 247 L 6 245 V 5 l 178 -1 z m -162 -15 l 147 1 V 23 L 22 23 z"
                                    variants={icon}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{
                                        default: { duration: 1.5, ease: 'easeInOut' },
                                        fill: { duration: 1.5, ease: [1, 0, 0.8, 1] },
                                    }}
                                />
                            </defs>
                            <clipPath id="SVGID_2_">
                                <use xlinkHref="#SVGID_1_" overflow="visible" />
                            </clipPath>

                            <linearGradient
                                id="SVGID_3_"
                                gradientUnits="userSpaceOnUse"
                                x1="-138.9677"
                                y1="665.1613"
                                x2="-137.9677"
                                y2="665.1613"
                                gradientTransform="matrix(177.3872 0 0 -177.3872 24658.6328 118118.7188)"
                            >
                                <stop offset="0" style={{ stopColor: '#1B1464' }} />
                                <stop offset="1" style={{ stopColor: '#0071BC' }} />
                            </linearGradient>
                            <rect
                                x="7.53"
                                y="7.41"
                                clipPath="url(#SVGID_2_)"
                                fill="url(#SVGID_3_)"
                                width="177.39"
                                height="240.41"
                                stroke="#000"
                            />
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}
