import { motion } from 'framer-motion';
import React from 'react';
import TopTenUsersContainer from '../LandingPage/TopTenUsersContainer';
export default function History() {
    return (
        <div className="bg-black">
            <motion.div
                className="flex bg-black shadow p-4 mt-16 text-white"
                animate={{
                    opacity: [0, 1],
                }}
                transition={{
                    duration: 2,
                    ease: 'easeIn',
                    times: [0, 0.3],
                }}
            ></motion.div>
            <div className="p-0 sm:p-6 pt-10 flex-1 bg-black">
                <TopTenUsersContainer />
            </div>
        </div>
    );
}
