import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { SingleDayPickerProps, SingleDayPickerState } from '../../../..';

export default class SingleDayPicker extends React.Component<SingleDayPickerProps, SingleDayPickerState> {
    handleDayChange = (selectedDay: Date) => {
        this.props.updateFormDate(selectedDay);
    };
    render() {
        return (
            <DayPickerInput
                inputProps={{
                    'data-lpignore': 'true',
                    className:
                        'appearance-none block w-full bg-black text-white border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-900 focus:border-gray-500',
                }}
                placeholder="Enter Target Date"
                value={this.props.targetDate}
                dayPickerProps={{
                    className: 'appearance-none block w-full bg-gray-300',
                    disabledDays: this.props.disabledDays,
                }}
                onDayChange={this.handleDayChange}
            />
        );
    }
}
