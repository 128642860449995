import { motion } from 'framer-motion';
import React, { Component } from 'react';
import dead from '../../assets/images/dead.svg';

export default class NotFound extends Component<{}, {}> {
    render() {
        return (
            <motion.div
                className="flex-col justify-center flex-wrap text-white bg-black  max-w-3xl m-auto p-8"
                animate={{
                    opacity: [0, 1],
                }}
                transition={{
                    duration: 5,
                    ease: 'easeIn',
                    times: [0, 0.2],
                }}
            >
                <img alt="404 page icon" className="w-full h-64 mt-32 items-center" src={dead}></img>
                <p className=" text-white flex justify-center flex-1 text-6xl">There's nothing to see here</p>
            </motion.div>
        );
    }
}
