import { motion } from 'framer-motion';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PredictionStatistic } from '../../..';
import betauserbadge from '../../assets/images/betauserbadge.svg';
import newuserbadge from '../../assets/images/newuserbadge.svg';
import { getPredictionStatistics } from '../../helper/DataServiceProvider';
import RankDisplay from '../RankDisplay';

toast.configure();

export default function Profile() {
    const elostyle = 'px-4 py-3 text-gray-300';
    const [statistics, setStatistics] = useState<PredictionStatistic | undefined>(undefined);
    useEffect(() => {
        let isMounted = true; // note this flag denote mount status
        getPredictionStatistics().then((predictionStat) => {
            if (isMounted && predictionStat !== undefined) {
                setStatistics(predictionStat);
            }
        });
        return () => {
            isMounted = false;
        }; // use effect cleanup to set flag false, if unmounted
    }, []);
    return (
        <motion.div
            className="flex justify-center mt-16 space-x-4 space-y-8 flex-wrap text-white bg-black"
            animate={{
                opacity: [0, 1],
                x: [-200, 0],
            }}
            transition={{
                duration: 2,
                ease: 'easeIn',
                times: [0, 0.3],
            }}
        >
            <div>
                {statistics !== undefined && (
                    <div id="predictionstats" className="mt-8 border border-gray-900 p-4 rounded">
                        <label className="text-2xl">Prediction Summary</label>
                        <div className="text-green-200">{statistics.Correct} &nbsp; Correct</div>
                        <div className="text-red-200">{statistics.Incorrect} &nbsp; Incorrect</div>
                        <div className="text-yellow-200">{statistics.InProgress} &nbsp; In progress</div>

                        <hr></hr>
                        <p>Total Predictions : {statistics.Total}</p>
                    </div>
                )}
                <div className="border border-gray-900 rounded p-4 mt-4">
                    <p className="text-2xl mb-4">Badge Shelf</p>
                    <div className="flex space-x-8">
                        <div>
                            <img alt="new user badge" className="w-24" src={newuserbadge}></img>
                            <p className="text-center">New User</p>
                        </div>
                        <div>
                            <img alt="beta user badge" className="w-24" src={betauserbadge}></img>
                            <p className="text-center">Beta User</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" w-64 border border-gray-900 rounded p-4 my-auto">
                <RankDisplay />
            </div>

            <div className="lg:w-1/4 w-full mx-auto overflow-auto">
                <table className="table-auto w-full text-center whitespace-no-wrap">
                    <thead>
                        <tr>
                            <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-blue-500 rounded-tl rounded-bl">
                                Rank
                            </th>
                            <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-blue-500">
                                ELO Rating
                            </th>
                            <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-blue-500">
                                Monthly Compensation Ceiling*
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-4 py-3 font-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-yellow-800 to-yellow-500">
                                Bronze
                            </td>
                            <td className={elostyle}>1000-1999</td>
                            <td className="px-4 py-3 text-lg text-gray-300">$0</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-3 font-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-gray-50 to-blue-200">
                                Silver
                            </td>
                            <td className={elostyle}>2000-2999</td>
                            <td className="px-4 py-3 text-lg text-gray-300 font-semibold">$0</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-3 font-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-yellow-200 to-yellow-600">
                                Gold
                            </td>
                            <td className={elostyle}>3000-3999</td>
                            <td className="px-4 py-3 text-lg text-gray-300 font-bold">$1500</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-3 font-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-blue-300">
                                Platinum
                            </td>
                            <td className={elostyle}>4000-4999</td>
                            <td className="px-4 py-3 text-lg text-gray-300 font-extrabold">$6500</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-3 font-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-gray-500 to-blue-400">
                                Diamond
                            </td>
                            <td className={elostyle}>5000+</td>
                            <td className="px-4 py-3 text-lg text-gray-300 font-black">$21500</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </motion.div>
    );
}
