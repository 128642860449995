import React, { useEffect, useState } from 'react';
import { getMyTitle } from '../helper/DataServiceProvider';

export default function MyTitle() {
    const [title, setTitle] = useState<string | undefined>(undefined);
    useEffect(() => {
        const getTitle = async function () {
            try {
                const title = await getMyTitle();
                console.log(title);
                if (title !== undefined) {
                    setTitle(title);
                } else {
                    console.log('cannot find title2');
                }
            } catch (error) {
                console.log('cannot find title');
            }
        };
        getTitle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let bgcolour = 'bg-red-500';
    if (title === 'analyst') {
        bgcolour = 'bg-green-500';
    } else if (title === 'verified analyst') {
        bgcolour = 'bg-blue-500';
    }
    let bg = 'px-2 mx-5 ' + bgcolour;
    return (
        <div>
            <span className="px-2 ">{title}</span>

            <span className={bg} style={{ borderRadius: '25px' }}>
                &#10003;
            </span>
        </div>
    );
}
