import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { RecentPrediction } from '../../..';
import { getCompanyLogoURL, getRecentPredictions } from '../../helper/DataServiceProvider';
import Logo from '../Logo';

export default function RecentPredictions() {
    const [recentPredictions, setRecentPredictions] = useState<RecentPrediction[]>([]);
    const [recentPredictionLogos, setRecentPredictionLogos] = useState<any>([]);
    useEffect(() => {
        const getRecentUserPredictions = async function () {
            try {
                const recentPredictions = await getRecentPredictions();
                if (recentPredictions !== undefined) {
                    for (let i = 0; i < recentPredictions.length; i++) {
                        const logoURLs = await getCompanyLogoURL(recentPredictions[i]['Ticker']);
                        try {
                            const companyLogoURL = logoURLs[0]._source.logo;
                            recentPredictionLogos.push(companyLogoURL);
                        } catch (error) {
                            recentPredictionLogos.push(undefined);
                        }
                    }
                    setRecentPredictionLogos(recentPredictionLogos);
                    setRecentPredictions(recentPredictions);
                    console.log(recentPredictionLogos);
                }
            } catch (error) {
                console.log('cannot retrieve recentpredictions');
            }
        };
        getRecentUserPredictions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="w-full">
            <p className="text-4xl tracking-tight leading-10 font-extrabold text-gray-300 sm:leading-none">
                Recent Predictions
            </p>
            <div className="flex flex-wrap justify-between mt-4">
                {recentPredictions &&
                    recentPredictions.length > 0 &&
                    recentPredictions.map(function (prediction, index) {
                        return (
                            <div className="w-64 h-64 " key={index}>
                                <motion.div
                                    className="flex items-center justify-center flex-col bg-gray-900 p-4 rounded-lg shadow-lg shadow-blue-500/50"
                                    animate={{
                                        opacity: [0, 1],
                                    }}
                                    transition={{
                                        duration: 3,
                                        ease: 'easeIn',
                                        times: [0, 0.6],
                                        delay: index,
                                    }}
                                >
                                    <p className="leading-relaxed text-centered text-gray-300 text-2xl">
                                        {prediction.Ticker}
                                    </p>
                                    <p className="leading-relaxed text-centered text-gray-300 mb-2">
                                        <Moment fromNow>{prediction.CreatedAt}</Moment>
                                    </p>
                                    {recentPredictionLogos && index < recentPredictionLogos.length && (
                                        <div className="w-32 h-32">
                                            <Logo
                                                url={recentPredictionLogos[index]}
                                                className={'rounded-full w-32 h-32'}
                                            />
                                        </div>
                                    )}
                                </motion.div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
