import { AnimatePresence, motion } from 'framer-motion';
import React, { Component } from 'react';
import { AboutDropdownProps, AboutDropdownState } from '../../..';
export default class AboutDropdown extends Component<AboutDropdownProps, AboutDropdownState> {
    dropdownMenu?: HTMLDivElement;
    constructor(props: any) {
        super(props);
        this.state = {
            showMenu: false,
        };
    }

    showMenu = (event: any) => {
        event.preventDefault();

        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    };

    closeMenu = (event: any) => {
        if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
            this.setState({ showMenu: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });
        }
    };

    render() {
        return (
            <motion.div className="overflow-x-hidden">
                <button
                    className="px-3 py-2 rounded-md text-xl font-medium  leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                    onMouseOver={this.showMenu}
                >
                    About
                </button>
                <AnimatePresence>
                    {this.state.showMenu && (
                        <motion.div
                            key={1}
                            animate={{
                                opacity: [0, 1],
                                y: [-20, 0],
                            }}
                            transition={{
                                duration: 2,
                                ease: 'easeIn',
                                times: [0, 0.15],
                            }}
                            exit={{ opacity: [1, 0], y: [0, -20] }}
                            className="origin-top-right absolute right-0 mt-2 w-64 p-4 rounded-md shadow-lg bg-black text-white ring-1 ring-black ring-opacity-5 border border-gray-900"
                            ref={(element) => {
                                this.dropdownMenu = element ? element : undefined;
                            }}
                        >
                            <a href="/about#about">
                                <div className="block px-4 py-2 text-lg text-gray-200 hover:bg-gray-100 hover:text-gray-900 text-center">
                                    About Us
                                </div>
                            </a>
                            <a href="/about#compensation">
                                <div className="block px-4 py-2 text-lg text-gray-200 hover:bg-gray-100 hover:text-gray-900 text-center">
                                    Compensation
                                </div>
                            </a>
                            <a href="/about#benefits">
                                <div className="block px-4 py-2 text-lg text-gray-200 hover:bg-gray-100 hover:text-gray-900 text-center">
                                    Benefit
                                </div>
                            </a>
                            <a href="/about#team">
                                <div className="block px-4 py-2 text-lg text-gray-200 hover:bg-gray-100 hover:text-gray-900 text-center">
                                    Our Team
                                </div>
                            </a>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
        );
    }
}
