import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function LandingSignUp(props: any) {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    return (
        <section className="text-gray-800 body-font">
            <div className="container px-5 pb-24 mx-auto flex flex-wrap pt-24 3xl:pt-0 xl:pl-64">
                <div className="bg-gray-900 rounded-lg p-8 flex flex-col w-full mt-10 md:mt-0 shadow-lg shadow-blue-500/50">
                    <h2 className="text-gray-300 text-lg font-medium title-font mb-5">
                        Share your financial analysis with us, risk-free.
                    </h2>
                    <div className="relative mb-4">
                        <div className="leading-7 text-sm text-gray-300">First Name</div>
                        <input
                            type="text"
                            id="firstName"
                            name="first-name"
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-300 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="relative mb-4">
                        <div className="leading-7 text-sm text-gray-300">Last Name</div>
                        <input
                            type="text"
                            id="lastName"
                            name="last-name"
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-300 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className="relative mb-4">
                        <div className="leading-7 text-sm text-gray-300">Email</div>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-black text-gray-300 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <Link
                        className="text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg"
                        to={{
                            pathname: '/register',
                            state: {
                                firstName: firstName,
                                lastName: lastName,
                                email: email,
                            },
                        }}
                    >
                        Register
                    </Link>
                    <p className="text-xs text-gray-500 mt-3">Welcome to the your Faction. </p>
                </div>
            </div>
        </section>
    );
}
