import axios from 'axios';
import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from './components/ErrorBoundary';
import Loading from './components/Loading';
import Routes from './components/Routes';
import { GlobalStateProvider, useGlobalState } from './helper/Context';
import { deleteCredentials, getCredentials } from './helper/CredentialManager';
import { checkToken } from './helper/DataServiceProvider';

const App = () => {
    const [state, dispatch] = useGlobalState();
    const originRequest = axios.CancelToken.source();
    useEffect(() => {
        async function checkAuthentication() {
            try {
                await checkToken(getCredentials().token, originRequest);
                // we are authenticated
                if (state.isAuthenticated !== true) {
                    dispatch({ isAuthenticated: true });
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    if (state.isAuthenticated !== false) {
                        dispatch({ isAuthenticated: false, message: 'Credentials expired. Please log back in!' });
                        deleteCredentials();
                    }
                }
            }
        }
        if (state.isAuthenticated !== false) {
            checkAuthentication();
        }
    });
    useEffect(() => {
        return () => {
            originRequest.cancel();
        };

        // eslint-disable-next-line
    }, []);
    if (state.isAuthenticated === undefined) {
        return (
            <div className="flex h-screen bg-black ">
                <div className="w-1/4 m-auto">
                    <Loading />
                </div>
            </div>
        );
    } else {
        return <Routes isAuthenticated={state.isAuthenticated} />;
    }
};

/**
 * App component.
 */
export const AppWrapper = () => {
    return (
        <GlobalStateProvider>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </GlobalStateProvider>
    );
};
