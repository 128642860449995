import { motion } from 'framer-motion';
import React from 'react';
import { PaginationProps } from '../..'; //test

enum Direction {
    LEFT = Number.MIN_VALUE,
    RIGHT = Number.MAX_VALUE,
}
const PAGE_NEIGHBORS = 2;
/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from: number, to: number, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }
    return range;
};

export default class Pagination extends React.Component<PaginationProps> {
    navigateBack = () => {
        if (this.props.pageNumber > 1) {
            this.props.navigate(this.props.pageNumber - 1);
        }
    };

    navigateForward = () => {
        if (this.props.pageNumber < this.props.totalPages) {
            this.props.navigate(this.props.pageNumber + 1);
        }
    };

    navigateToIndex = (e: any) => {
        const selectedNumber = parseInt(e.target.value);
        if (selectedNumber !== this.props.pageNumber) {
            this.props.navigate(selectedNumber);
        }
    };

    /**
     * Let's say we have 10 pages and we set pageNeighbours to 2
     * Given that the current page is 6
     * The pagination control will look like the following:
     *
     * (1) < {4 5} [6] {7 8} > (10)
     *
     * (x) => terminal pages: first and last page(always visible)
     * [x] => represents current page
     * {...x} => represents page neighbours
     */
    fetchPageNumbers = (): Array<number | Direction> => {
        const totalPages = this.props.totalPages;
        const currentPage = this.props.pageNumber;

        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = PAGE_NEIGHBORS * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - PAGE_NEIGHBORS);
            const endPage = Math.min(totalPages - 1, currentPage + PAGE_NEIGHBORS);

            let pages: Array<number> = range(startPage, endPage);

            /**
             * hasLeftOffset: has hidden pages to the left
             * hasRightOffset: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftOffset = startPage > 2;
            const hasRightOffset = totalPages - endPage > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            if (hasLeftOffset && !hasRightOffset) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                // generates < {5 6} [7] {8 9}
                const extraPages = range(startPage - spillOffset, startPage - 1);
                pages = [Direction.LEFT, ...extraPages, ...pages];
            } else if (!hasLeftOffset && hasRightOffset) {
                // handle: (1)  {2 3} [4] {5 6} > (10)
                // generates {2} [3] {4 5} >
                const extraPages = range(endPage + 1, endPage + spillOffset);
                pages = [...pages, ...extraPages, Direction.RIGHT];
            } else {
                // handle: (1) < {4 5} [6] {7 8} > (10)
                // generates < {4 5} [6] {7 8} >
                pages = [Direction.LEFT, ...pages, Direction.RIGHT];
            }

            return [1, ...pages, totalPages];
        }
        return range(1, totalPages);
    };

    render() {
        let pageNumbers: Array<JSX.Element> = [];
        const pages = this.fetchPageNumbers();

        pageNumbers = pages.map((page) => {
            const buttonstyle =
                'relative inline-flex items-center md:px-5 px-3  py-4 border leading-5 font-medium text-gray-400 border-gray-800 hover:border-blue-500  transition ease-in-out duration-150';
            const currpagebuttonstyle =
                'relative inline-flex items-center md:px-5 px-3  py-4 border leading-5 font-medium text-gray-400 border-gray-1600 hover:border-blue-500  transition ease-in-out duration-150';
            if (page === Direction.LEFT) {
                return (
                    <button className={buttonstyle} id="navigateBack" key={page} onClick={this.navigateBack}>
                        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" />
                        </svg>
                    </button>
                );
            } else if (page === Direction.RIGHT) {
                return (
                    <button className={buttonstyle} id="navigateForward" key={page} onClick={this.navigateForward}>
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" />
                        </svg>
                    </button>
                );
            } else if (page === this.props.pageNumber) {
                const pageBorder: string = this.props.pageNumber === page ? 'border-green-400' : 'border-gray-800';
                return (
                    <button
                        className={currpagebuttonstyle + pageBorder}
                        value={page}
                        key={page}
                        onClick={this.navigateToIndex}
                    >
                        {page}
                    </button>
                );
            } else {
                const pageBorder: string = this.props.pageNumber === page ? 'border-green-400' : 'border-gray-800';
                return (
                    <button className={buttonstyle + pageBorder} value={page} key={page} onClick={this.navigateToIndex}>
                        {page}
                    </button>
                );
            }
        });

        return (
            <motion.div
                animate={{
                    opacity: [0, 1],
                }}
                transition={{
                    duration: 8,
                    ease: 'easeIn',
                    times: [0, 0.2],
                }}
                className="bg-black  flex items-center justify-between sm:px-6"
                id="pagination"
            >
                <div className="sm:flex-1  sm:items-center sm:justify-between">
                    <div>
                        <nav className="relative inline-flex shadow-sm ">{pageNumbers}</nav>
                    </div>
                    <div>
                        <p className=" leading-5 text-gray-500 pt-1">
                            <span className="font-medium">{this.props.totalPredictionCount} &nbsp; </span>
                            results
                        </p>
                    </div>
                </div>
            </motion.div>
        );
    }
}
