import { motion } from 'framer-motion';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { updateEmailSubscription, getEmailSubscription } from '../../helper/DataServiceProvider';

toast.configure();
const toastProperties: ToastOptions = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 3000,
    hideProgressBar: true,
};

export default function Setting() {
    const [promoEmailStatus, setPromoEmailStatus] = useState<boolean | undefined>(undefined);
    const [predictionEmailCompletedStatus, setPredictionEmailCompletedStatus] = useState<boolean | undefined>(
        undefined
    );
    const updatePromoEmailStatus = function () {
        const desiredPromoStatus = !promoEmailStatus;
        setPromoEmailStatus(desiredPromoStatus);
        updateEmailSubscription(desiredPromoStatus, undefined).then(() => {
            if (desiredPromoStatus) {
                toast.success('Promo email status subscription is enabled', toastProperties);
            } else {
                toast.success('Promo email status subscription is disabled', toastProperties);
            }
        });
    };
    const updatePredictionEmailStatus = function () {
        const desiredPromoStatus = !predictionEmailCompletedStatus;
        setPredictionEmailCompletedStatus(desiredPromoStatus);
        updateEmailSubscription(undefined, desiredPromoStatus).then(() => {
            if (desiredPromoStatus) {
                toast.success('Prediction reminder email subscription is enabled', toastProperties);
            } else {
                toast.success('Prediction reminder email completion status subscription is disabled', toastProperties);
            }
        });
    };
    useEffect(() => {
        let isMounted = true; // note this flag denote mount status
        getEmailSubscription().then((subscriptionData) => {
            if (isMounted) {
                if (subscriptionData.allow_promo !== undefined) {
                    setPromoEmailStatus(subscriptionData.allow_promo);
                }
                if (subscriptionData.allow_prediction_completed !== undefined) {
                    setPredictionEmailCompletedStatus(subscriptionData.allow_prediction_completed);
                }
            }
        });
        return () => {
            isMounted = false;
        }; // use effect cleanup to set flag false, if unmounted
    }, []);
    return (
        <motion.div
            className="flex justify-center mt-16 space-x-4 space-y-8 flex-wrap text-white bg-black"
            animate={{
                opacity: [0, 1],
                x: [-200, 0],
            }}
            transition={{
                duration: 2,
                ease: 'easeIn',
                times: [0, 0.3],
            }}
        >
            <div>
                {promoEmailStatus !== undefined && predictionEmailCompletedStatus !== undefined && (
                    <div className="border border-gray-900 rounded p-4 mt-4">
                        <p className="text-2xl mb-4">Preferences</p>
                        <div className="inline">
                            Email
                            <div>
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        updatePredictionEmailStatus();
                                    }}
                                    checked={predictionEmailCompletedStatus}
                                ></input>
                                <p className="text-center inline"> Evaluated prediction reminder</p>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        updatePromoEmailStatus();
                                    }}
                                    checked={promoEmailStatus}
                                ></input>
                                <p className="text-center inline"> Promotional</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </motion.div>
    );
}
