import { motion } from 'framer-motion';
import React from 'react';
import { PredictionCardContainerProps, PredictionCardContainerState } from '../../../../index';
import { getPredictionsWithFilter } from '../../../helper/DataServiceProvider';
import Loading from '../../Loading';
import Pagination from '../../Pagination';
import HistoryTable from './HistoryTable';
export default class PredictionCardContainer extends React.Component<
    PredictionCardContainerProps,
    PredictionCardContainerState
> {
    constructor(props: PredictionCardContainerProps) {
        super(props);
        this.state = { visiblePredictions: [], totalPages: 0, pageNumber: 1, minRange: 0, maxRange: 0 };
    }

    async updateVisiblePredictions(pageNumber: number) {
        if (this.props.totalPredictionCount !== undefined) {
            const totalPages = Math.ceil(this.props.totalPredictionCount / this.props.maxCardsPerPage);
            // initial page number is 1
            // called when pageNumber is changed, pagenumber goes from 1 to totalPages, so we need to subtract 1 from it to zero the index
            const minRange = (pageNumber - 1) * this.props.maxCardsPerPage;
            const maxRange = Math.min(this.props.totalPredictionCount, pageNumber * this.props.maxCardsPerPage);
            this.setState({ minRange });
            this.setState({ maxRange });
            let visiblePredictions: any;
            try {
                visiblePredictions = await getPredictionsWithFilter(this.props.filter, minRange, maxRange);
            } catch (error) {
                if (!error.toString().toLowerCase().includes('request aborted')) {
                    this.setState(() => {
                        throw error;
                    });
                }
            }
            this.setState({ visiblePredictions });
            this.setState({ totalPages });
        }
    }

    async componentDidUpdate(prevProps: PredictionCardContainerProps) {
        if (this.props.totalPredictionCount !== prevProps.totalPredictionCount) {
            // if the totalPredictionCount is updated, update the current page but move to first page
            await this.updateVisiblePredictions(1);
            this.setState({ pageNumber: 1 });
        }
    }

    handleChange = (value: number) => {
        this.updateVisiblePredictions(value).then(() => {
            console.log('moved to a page ' + value);
        });
        this.setState({ pageNumber: value });
    };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        if (this.props.totalPredictionCount === undefined) {
            // Render Loading State
            return (
                <div className="w-1/4 m-auto">
                    <Loading />
                </div>
            );
        } else if (this.props.totalPredictionCount === 0 && this.props.filter === undefined) {
            return (
                <div>
                    <div className="text-white text-5xl"> Make your first prediction</div>
                </div>
            );
        } else if (this.props.totalPredictionCount === 0 && this.props.filter !== undefined) {
            return <h1 className="text-white"> No matching predictions </h1>;
        } else {
            const predictionDetails =
                this.state.visiblePredictions !== undefined &&
                this.state.visiblePredictions.map((prediction: any, index: number) => {
                    return (
                        <motion.div
                            key={index}
                            className=""
                            id="Prediction Cards from Prediction Card Container"
                            animate={{
                                y: [64, 0],
                            }}
                            transition={{
                                duration: 1,
                                ease: 'easeIn',
                                times: [0, 0.5],
                            }}
                        >
                            <HistoryTable
                                id={prediction.ID}
                                interactable={true}
                                detailed={this.props.detailed}
                                exchange={prediction.Exchange}
                                targetDate={prediction.TargetDate}
                                targetPrice={prediction.TargetPrice}
                                priceAtCreation={prediction.PriceAtCreation}
                                timeAtCreation={prediction.TimeAtCreation}
                                accuracy={prediction.Accuracy}
                                dayDifference={prediction.DayDifference}
                                percentageChange={prediction.PercentageChange}
                                correctDirection={prediction.CorrectDirection}
                                name={prediction.Name}
                                ticker={prediction.Ticker}
                                comments={prediction.Comments}
                            />
                        </motion.div>
                    );
                });
            return (
                <div>
                    <motion.div
                        animate={{
                            opacity: [0, 1],
                        }}
                        transition={{
                            duration: 1,
                            ease: 'easeIn',
                            times: [0, 0.5],
                        }}
                        className="font-bold text-xl mb-2 text-white"
                    ></motion.div>
                    <div style={{ overflowX: 'scroll' }}>
                        <div className="container mx-auto px-4 sm:px-8 max-w-full border-b border-gray-200">
                            <div className="py-8">
                                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                        <table className="min-w-full table-auto">
                                            <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-5 py-3  bg-black  border-b border-gray-200 text-white text-center text-lg uppercase font-normal"
                                                        style={{
                                                            width: 100 / 6 + '%',
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Company Name
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-5 py-3  bg-black border-b border-gray-200 text-white text-center text-lg uppercase font-normal width: 20%"
                                                        style={{
                                                            width: 100 / 6 + '%',
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Current Price
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-5 py-3  bg-black border-b border-gray-200 text-white text-center text-lg uppercase font-normal width: 20%"
                                                        style={{
                                                            width: 100 / 6 + '%',
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Target Price
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-5 py-3  bg-black border-b border-gray-200 text-white text-center text-lg uppercase font-normal width: 20%"
                                                        style={{
                                                            width: 100 / 6 + '%',
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Start Date
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-5 py-3  bg-black border-b border-gray-200 text-white text-center text-lg uppercase font-normal width: 20%"
                                                        style={{
                                                            width: 100 / 6 + '%',
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Target Date
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                        {predictionDetails}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ;
                    <Pagination
                        totalPages={this.state.totalPages}
                        totalPredictionCount={this.props.totalPredictionCount}
                        navigate={this.handleChange}
                        pageNumber={this.state.pageNumber}
                        min={this.state.minRange}
                        max={this.state.maxRange}
                    />
                </div>
            );
        }
    }
}
