import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import {
    CurrentPrice,
    HistoricalPrice,
    LoginCredentials,
    Name,
    Prediction,
    PredictionRequest,
    PredictionStatistic,
    RegistrationDetails,
    SubscriptionDetails,
} from '../../index';
import { getCredentials } from './CredentialManager';

const baseurl = process.env.REACT_APP_BASE_URL;
const baseRoute = baseurl;

export async function tryLogin(credentials: LoginCredentials): Promise<Credential | undefined> {
    try {
        const request: Promise<AxiosResponse> = axios.post(baseRoute + '/api/login', credentials, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const response = await makeResponse(request);
        if (response.data !== undefined && response.data.account !== undefined) {
            return response.data.account;
        }
        throw new Error(response.data.message);
    } catch (error) {
        if (!error.toString().toLowerCase().includes('request aborted')) {
            throw error;
        }
    }
}

export async function getName(cancelTokenSource: CancelTokenSource): Promise<Name> {
    try {
        const request: Promise<AxiosResponse> = axios.get(baseRoute + '/api/user/getname', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
            cancelToken: cancelTokenSource.token,
        });
        const response = await makeResponse(request);
        if (response.data !== undefined && response.data.name !== undefined) {
            return response.data.name;
        }
        throw new Error(response.data.message);
    } catch (error) {
        if (!error.toString().toLowerCase().includes('request aborted')) {
            throw error;
        }
        return { FirstName: '', LastName: '' };
    }
}

async function makeResponse(request: Promise<AxiosResponse>): Promise<AxiosResponse> {
    try {
        const response: AxiosResponse = await request;
        return response;
    } catch (error) {
        throw error;
    }
}

function getToken(): string {
    return 'Bearer ' + getCredentials().token;
}

export async function tryRegister(registrationData: RegistrationDetails): Promise<string | undefined> {
    if (registrationData.birthday) {
        registrationData.birthday += 'T12:00:00.000Z';
    }
    try {
        const request: Promise<AxiosResponse> = axios.post(baseRoute + '/api/new', registrationData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const response = await makeResponse(request);
        if (response.data.status === true) {
            return response.data.message;
        }
        throw new Error(response.data.message);
    } catch (e) {
        throw e;
    }
}

export async function requestForgotPasswordLink(email: string): Promise<any | undefined> {
    const emailData = { email: email };
    try {
        const request: Promise<AxiosResponse> = axios.post(baseRoute + '/api/requestforgotpasswordlink', emailData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const response = await makeResponse(request);
        if (response.data.status) {
            return response.data.message;
        }
        throw new Error(response.data.message);
    } catch (e) {
        throw e;
    }
}

export async function checkPasswordResetLink(token: string): Promise<any | undefined> {
    const tokenData = { token: token };
    try {
        const request: Promise<AxiosResponse> = axios.post(baseRoute + '/api/checkforgotpasswordlink', tokenData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const response = await makeResponse(request);
        if (response.data.status) {
            return response.data.message;
        }
        throw new Error(response.data.message);
    } catch (e) {
        throw e;
    }
}

export async function resetPassword(token: string, newPassword: string): Promise<any | undefined> {
    const passwordData = { token: token, newPassword: newPassword };
    try {
        const request: Promise<AxiosResponse> = axios.post(baseRoute + '/api/resetpassword', passwordData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const response = await makeResponse(request);
        if (response.data.status) {
            return response.data.message;
        }
        throw new Error(response.data.message);
    } catch (e) {
        throw e;
    }
}

export async function verifyAccount(token: string): Promise<any | undefined> {
    const tokenData = { token: token };
    try {
        const request: Promise<AxiosResponse> = axios.post(baseRoute + '/api/verifyaccount', tokenData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const response = await makeResponse(request);
        if (response.data.status) {
            return response.data.message;
        }
        throw new Error(response.data.message);
    } catch (e) {
        throw e;
    }
}

export async function getAPIVersion(): Promise<[string, string]> {
    try {
        const request: Promise<AxiosResponse> = axios.get(baseRoute + '/api/getversion');
        const response = await makeResponse(request);
        return [response.data.hash, response.data.commit.Author.When];
    } catch (e) {
        return ['N/A', 'N/A'];
    }
}

export async function submitPrediction(prediction: PredictionRequest): Promise<AxiosResponse | undefined> {
    try {
        const request: Promise<AxiosResponse> = axios.post(baseRoute + '/api/user/predictions/new', prediction, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
        });
        const response = await makeResponse(request);
        return response;
    } catch (e) {}
}

export async function checkToken(
    token: string,
    cancelTokenSource: CancelTokenSource
): Promise<AxiosResponse | undefined> {
    try {
        const request: Promise<AxiosResponse> = axios.post(
            baseRoute + '/api/user/authcheck',
            {}, // empty body cause Axios is shit and needs this
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                cancelToken: cancelTokenSource.token,
            }
        );
        const response = await makeResponse(request);
        return response;
    } catch (error) {
        console.log(error);
        if (!error.toString().toLowerCase().includes('request aborted')) {
            throw error;
        }
    }
}

export async function getAutosuggestions(ticker: string): Promise<Array<any> | undefined> {
    try {
        const request: Promise<AxiosResponse> = axios.post(
            baseRoute + '/api/user/autosuggest',
            {
                input: ticker,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        );
        const response = await makeResponse(request);
        return response.data.data.hits;
    } catch (e) {}
}

export async function getCompanyLogoURL(ticker: string): Promise<any> {
    try {
        const request: Promise<AxiosResponse> = axios.post(
            baseRoute + '/api/getlogo',
            {
                company: ticker,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        const response = await makeResponse(request);
        return response.data.data.hits;
    } catch (e) {}
}

export async function getRecentPredictions(): Promise<any> {
    try {
        const request: Promise<AxiosResponse> = axios.post(baseRoute + '/api/predictions/recent', {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const response = await makeResponse(request);
        return response.data.data;
    } catch (e) {}
}

export async function getTopTenUsers(): Promise<any> {
    try {
        const request: Promise<AxiosResponse> = axios.get(baseRoute + '/api/account/gettoptenusers', {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const response = await makeResponse(request);
        return response.data.data;
    } catch (e) {}
}

export async function getPredictionsCount(): Promise<number | undefined> {
    try {
        const request: Promise<AxiosResponse> = axios.get(baseRoute + '/api/user/predictions', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
        });
        const response = await makeResponse(request);
        if (response.data !== undefined && response.data.data !== undefined) {
            return response.data.data;
        }
        throw new Error(response.data.message);
    } catch (e) {}
}

export async function getPredictionsCountWithFilter(filter: string | undefined): Promise<any> {
    if (filter === undefined || filter === '') {
        return getPredictionsCount();
    }
    try {
        const request: Promise<AxiosResponse> = axios.post(
            baseRoute + '/api/user/predictions/filter/count',
            {
                filter: filter,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        );
        const response = await makeResponse(request);
        if (response.data !== undefined && response.data.data !== undefined) {
            return response.data.data;
        }
        throw new Error(response.data.message);
    } catch (e) {}
}

export async function getPredictionsWithFilter(
    filter: string | undefined,
    minRange: number,
    maxRange: number
): Promise<any> {
    if (filter === undefined || filter === '') {
        return getPredictions(minRange, maxRange);
    }
    try {
        const request: Promise<AxiosResponse> = axios.post(
            baseRoute + '/api/user/predictions/filter',
            {
                filter: filter,
                minRange: minRange,
                maxRange: maxRange,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        );
        const response = await makeResponse(request);
        if (response.data !== undefined && response.data.data !== undefined) {
            return response.data.data;
        }
        throw new Error(response.data.message);
    } catch (error) {
        if (!error.toString().toLowerCase().includes('request aborted')) {
            throw error;
        }
    }
}

export async function getMyTitle(): Promise<any> {
    try {
        const request: Promise<AxiosResponse> = axios.post(
            baseRoute + '/api/user/getmytitle',
            {},

            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        );
        const response = await makeResponse(request);
        return response.data.data;
    } catch (e) {}
}

async function getPredictions(minRange: number, maxRange: number): Promise<Prediction[] | undefined> {
    const range = { MinRange: minRange, MaxRange: maxRange };
    try {
        const request: Promise<AxiosResponse> = axios.post(baseRoute + '/api/user/predictions', range, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
        });
        const response = await makeResponse(request);
        if (response.data !== undefined && response.data.data !== undefined) {
            return response.data.data;
        }
        throw new Error(response.data.message);
    } catch (error) {
        if (!error.toString().toLowerCase().includes('request aborted')) {
            throw error;
        }
    }
}

export async function getHistoricalStockPrices(
    ticker: string,
    initialDate: string,
    endDate: string
): Promise<Array<HistoricalPrice> | undefined> {
    const priceData = { ticker: ticker, initialDate: initialDate, endDate: endDate };
    try {
        const request: Promise<AxiosResponse> = axios.post(baseRoute + '/api/predictions/prices', priceData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const response = await makeResponse(request);
        if (response.data !== undefined && response.data.data !== undefined) {
            return response.data.data;
        }
        throw new Error(response.data.message);
    } catch (e) {}
}

export async function getCurrentStockPrice(ticker: string): Promise<CurrentPrice | undefined> {
    const priceData = { ticker: ticker };
    try {
        const request: Promise<AxiosResponse> = axios.post(
            baseRoute + '/api/user/predictions/currentprice',
            priceData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        );
        const response = await makeResponse(request);
        if (response.data !== undefined && response.data.data !== undefined) {
            return response.data.data;
        }
        throw new Error(response.data.message);
    } catch (e) {}
}

export async function getUserRating(): Promise<number | undefined> {
    try {
        const request: Promise<AxiosResponse> = axios.get(baseRoute + '/api/user/rating', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
        });
        const response = await makeResponse(request);
        return response.data.rating;
    } catch (e) {
        return undefined;
    }
}

export async function getPredictionStatistics(): Promise<PredictionStatistic | undefined> {
    try {
        const request: Promise<AxiosResponse> = axios.get(baseRoute + '/api/user/predictionstats', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
        });
        const response = await makeResponse(request);
        return response.data.data;
    } catch (e) {
        return undefined;
    }
}

export async function getPredictionDetails(token: string): Promise<PredictionStatistic | undefined> {
    const predictionID = { predictionID: token };
    try {
        const request: Promise<AxiosResponse> = axios.post(baseRoute + '/api/getprediction', predictionID, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const response = await makeResponse(request);
        if (response !== undefined && response.data !== undefined && response.data.prediction !== undefined) {
            return response.data.prediction;
        }
        throw new Error(response.data.message);
    } catch (e) {
        throw e;
    }
}

export async function getEmailSubscription(): Promise<SubscriptionDetails> {
    try {
        const request: Promise<AxiosResponse> = axios.get(baseRoute + '/api/user/preferences', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
        });
        const response = await makeResponse(request);
        if (response !== undefined && response.data !== undefined) {
            return response.data;
        }
        throw new Error(response.data.message);
    } catch (e) {
        throw e;
    }
}

export async function updateEmailSubscription(
    newPromoEmailStatus: boolean | undefined,
    newPredictionEmailCompletedStatus: boolean | undefined
): Promise<AxiosResponse | undefined> {
    try {
        const newSubscriptionStatusBody: SubscriptionDetails = {};
        if (newPromoEmailStatus !== undefined) {
            newSubscriptionStatusBody.allow_promo = newPromoEmailStatus;
        }
        if (newPredictionEmailCompletedStatus !== undefined) {
            newSubscriptionStatusBody.allow_prediction_completed = newPredictionEmailCompletedStatus;
        }
        const request: Promise<AxiosResponse> = axios.post(
            baseRoute + '/api/user/preferences',
            newSubscriptionStatusBody,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        );
        const response = await makeResponse(request);
        if (response !== undefined && response.data !== undefined) {
            return response.data;
        }
        throw new Error(response.data.message);
    } catch (e) {
        throw e;
    }
}
