import { AnimatePresence, motion } from 'framer-motion';
import React, { Component } from 'react';
import { AccountDropdownProps, AccountDropdownState } from '../../..';
import RankDisplay from '../RankDisplay';
import MyTitle from '../Title';
export default class AccountDropdown extends Component<AccountDropdownProps, AccountDropdownState> {
    dropdownMenu?: HTMLDivElement;
    constructor(props: any) {
        super(props);
        this.state = {
            showMenu: false,
        };
    }

    logout = () => {
        // kinda of a hack to log out.
        this.setState(() => {
            console.log('logging out');
            throw new Error();
        });
    };

    showMenu = (event: any) => {
        event.preventDefault();

        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    };

    closeMenu = (event: any) => {
        if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
            this.setState({ showMenu: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });
        }
    };

    render() {
        return (
            <motion.div className="overflow-x-hidden">
                <button
                    className="px-3 py-2 rounded-md text-base font-medium  text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out "
                    onClick={this.showMenu}
                >
                    Account
                </button>
                <AnimatePresence>
                    {this.state.showMenu && (
                        <motion.div
                            key={1}
                            animate={{
                                opacity: [0, 1],
                                y: [-20, 0],
                            }}
                            transition={{
                                duration: 2,
                                ease: 'easeIn',
                                times: [0, 0.15],
                            }}
                            exit={{ opacity: [1, 0], y: [0, -20] }}
                            className="origin-top-right absolute right-0 mt-2 w-64 p-4 rounded-md shadow-lg bg-black text-white ring-1 ring-black ring-opacity-5 border border-gray-900"
                            ref={(element) => {
                                this.dropdownMenu = element ? element : undefined;
                            }}
                        >
                            <div className="block px-4 py-2 text-xl text-gray-400 text-center">
                                {' '}
                                {this.props.firstName + ' ' + this.props.lastName}
                            </div>
                            <div className="text-center">
                                <MyTitle></MyTitle>
                            </div>

                            <RankDisplay></RankDisplay>
                            <a href="/profile">
                                <div className="block bg-gray-800 px-4 py-2 text-lg text-gray-400 hover:bg-gray-100 hover:text-gray-900 text-center">
                                    Profile
                                </div>
                            </a>
                            <a href="/setting">
                                <div className="block bg-gray-800 px-4 py-2 text-lg text-gray-400 hover:bg-gray-100 hover:text-gray-900 text-center">
                                    Setting
                                </div>
                            </a>
                            <a
                                href="#logout"
                                onMouseDown={(event: React.MouseEvent<HTMLElement>) => {
                                    console.log('clicked logout');
                                    this.logout();
                                }}
                            >
                                <div className="block bg-gray-800 px-4 py-2 text-lg text-gray-400 hover:bg-gray-100 hover:text-gray-900 text-center">
                                    Logout
                                </div>
                            </a>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
        );
    }
}
