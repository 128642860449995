import React, { Component } from 'react';
import Moment from 'react-moment';
import { PredictionDialogProps } from '../../../..';
import { submitPrediction } from '../../../helper/DataServiceProvider';

export default class PredictionDialog extends Component<PredictionDialogProps> {
    completeSubmission = (event: any) => {
        // user pressed ok
        event.preventDefault();
        console.log('you clicked submit');
        if (this.props.predictionRequest === undefined) {
            this.props.setDialog(false);
            return;
        }
        submitPrediction(this.props.predictionRequest)
            .then((response: any) => {
                if (response.data.status === true) {
                    this.props.updatePredictionCount().then(() => {
                        console.log('Prediction Count Updated');
                        // reset form
                        this.props.resetForm();
                    });
                } else {
                    console.log('prediction submission failed');
                    this.props.setSubmissionError(response.data.message);
                    console.log(response.data.message);
                }
            })
            .catch((e) => {
                if (e.message.includes('status code 403')) {
                    const errorMessage = 'Credentials expired. Please relogin';
                    this.props.throwError(errorMessage);
                } else {
                    console.log('Something went wrong with prediction');
                    this.props.setSubmissionError(e.message);
                }
            })
            .finally(() => {
                this.props.setDialog(false);
            });
    };
    cancelSubmission = (event: any) => {
        // user pressed ok
        event.preventDefault();
        console.log('you clicked cancel');
        this.props.setDialog(false);
    };
    render() {
        if (this.props.showDialog && this.props.predictionRequest) {
            return (
                <div className="fixed z-10 inset-0 overflow-y-auto ">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <div
                            className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                        >
                            <div className=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-2xl leading-6 font-medium text-white" id="modal-headline">
                                            Prediction Confirmation
                                        </h3>
                                        <div className="mt-2 text-white">
                                            <p className="text-gray-500">
                                                Are you sure you want to submit this prediction?
                                            </p>
                                            <div className="py-4 ">
                                                {this.props.predictionRequest.exchange}:{' '}
                                                <div className="text-lg inline font-bold">
                                                    {this.props.predictionRequest.ticker}
                                                </div>
                                            </div>
                                            <div>
                                                From{' '}
                                                <div className="text-lg inline font-bold">
                                                    ${this.props.currentTickerPrice}{' '}
                                                </div>
                                                to
                                                <div className="text-lg inline font-bold">
                                                    {' '}
                                                    ${this.props.predictionRequest.targetPrice}{' '}
                                                </div>
                                                on{' '}
                                                <div className="text-lg inline font-bold">
                                                    <Moment format="dddd MMMM Do">
                                                        {this.props.predictionRequest.targetDate}
                                                    </Moment>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={this.completeSubmission}
                                >
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={this.cancelSubmission}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}
