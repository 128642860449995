import React from 'react';
import { ContextProps } from '../../index';
import { containsToken, getCredentials } from './CredentialManager';

const defaultContext: ContextProps = containsToken()
    ? {
          isAuthenticated: undefined,
          userToken: getCredentials().token,
          message: undefined,
      }
    : {
          isAuthenticated: false,
          userToken: undefined,
          message: undefined,
      };
const GlobalStateContext = React.createContext(defaultContext);
const DispatchStateContext = React.createContext({});

export const GlobalStateProvider = ({ children }: { children: any }) => {
    const [state, dispatch] = React.useReducer(
        (state: ContextProps, newValue: any) => ({ ...state, ...newValue }),
        defaultContext
    );
    return (
        <GlobalStateContext.Provider value={state}>
            <DispatchStateContext.Provider value={dispatch}>{children}</DispatchStateContext.Provider>
        </GlobalStateContext.Provider>
    );
};

export const useGlobalState = (): [ContextProps, any] => [
    React.useContext(GlobalStateContext),
    React.useContext(DispatchStateContext),
];
