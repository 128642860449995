import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Name } from '../../..';
import FFlogo from '../../assets/images/factionfinancialwhite.png';
import { getName } from '../../helper/DataServiceProvider';
import { Page } from '../Routes';
import AccountDropdown from './AccountDropdown';

export default function Navbar(props: { currentPage: Page }) {
    const [mobileMenu, showMobileMenu] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const originRequest = axios.CancelToken.source();
    async function getFullName() {
        const nameOfUser: Name = await getName(originRequest);
        setFirstName(nameOfUser.FirstName);
        setLastName(nameOfUser.LastName);
    }
    useEffect(() => {
        getFullName();
        return () => {
            originRequest.cancel();
        };
        // eslint-disable-next-line
    }, []);
    return (
        <nav className="fixed w-full z-50 bg-black xl:bg-transparent ">
            <div className=" mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        {/* <!-- Mobile menu button--> */}
                        <button
                            onClick={() => {
                                showMobileMenu(!mobileMenu);
                            }}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
                        >
                            {/* <!-- Icon when menu is closed. --> */}
                            {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
                            <svg className="block h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                            {/* <!-- Icon when menu is open. --> */}
                            {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
                            <svg className="hidden h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <a href="/">
                            <img alt="Faction Financial Logo" className="h-10" src={FFlogo}></img>
                        </a>
                    </div>{' '}
                    <a href="/">
                        <img alt="Faction Financial Logo" className="h-10 invisible sm:visible" src={FFlogo}></img>
                    </a>
                    <div className="flex-1 flex items-center justify-end sm:items-stretch sm:justify-end">
                        <div className="flex-shrink-0">
                            <img
                                className="block lg:hidden h-8 w-auto"
                                src="/img/logos/workflow-mark-on-dark.svg"
                                alt=""
                            />
                            <img
                                className="hidden lg:block h-8 w-auto"
                                src="/img/logos/workflow-logo-on-dark.svg"
                                alt=""
                            />
                        </div>
                        <div className="hidden sm:block sm:mr-auto">
                            <div className="flex">
                                <NavLink
                                    to="/dashboard"
                                    className={
                                        props.currentPage === Page.DASHBOARD
                                            ? 'px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                            : 'px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                    }
                                >
                                    Dashboard
                                </NavLink>
                                <NavLink
                                    to="/history"
                                    className={
                                        props.currentPage === Page.HISTORY
                                            ? 'px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                            : 'px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                    }
                                >
                                    History
                                </NavLink>
                                <NavLink
                                    to="/bestanalyst"
                                    className={
                                        props.currentPage === Page.BESTANALYST
                                            ? 'px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                            : 'px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                    }
                                >
                                    Best Analysts
                                </NavLink>
                                <NavLink
                                    to="/authrecom"
                                    className={
                                        props.currentPage === Page.AUTHRECOM
                                            ? 'px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                            : 'px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                    }
                                >
                                    AI Recommendations
                                </NavLink>
                            </div>
                        </div>

                        <AccountDropdown firstName={firstName} lastName={lastName} />
                    </div>
                </div>
            </div>
            {/* <!--
    Mobile menu, toggle classNames based on menu state.

    Menu open: "block", Menu closed: "hidden"
  --> */}
            <div className={mobileMenu ? 'block sm:hidden' : 'hidden sm:hidden'}>
                <div className="m-1 flex-col">
                    <div className="py-3">
                        <NavLink
                            to="/dashboard"
                            className={
                                props.currentPage === Page.DASHBOARD
                                    ? 'px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                    : 'px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                            }
                        >
                            Dashboard
                        </NavLink>
                    </div>
                    <div className="py-3">
                        <NavLink
                            to="/history"
                            className={
                                props.currentPage === Page.HISTORY
                                    ? 'px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                    : 'px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                            }
                        >
                            History
                        </NavLink>
                    </div>
                    <div className="py-3">
                        <NavLink
                            to="/profile"
                            className={
                                props.currentPage === Page.PROFILE
                                    ? 'px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                    : 'px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                            }
                        >
                            Profile
                        </NavLink>
                        <NavLink
                            to="/bestanalyst"
                            className={
                                props.currentPage === Page.BESTANALYST
                                    ? 'px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                    : 'px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                            }
                        >
                            Best Analysts
                        </NavLink>
                        <NavLink
                            to="/authrecom"
                            className={
                                props.currentPage === Page.AUTHRECOM
                                    ? 'px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                                    : 'px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'
                            }
                        >
                            AI Recommendations
                        </NavLink>
                    </div>
                </div>
            </div>
        </nav>
    );
}
